import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { datadogRum } from "@datadog/browser-rum";
import DealerCompanion from "./components/dealer/dealercompanion";
import SignUp from "./components/signup/signup";
import Login from "./components/login/login";
import LinkedInLoginView from "./components/login/LinkedInLoginView";
import VideoSettings from "./components/videoSettings/VideoSettings";
import Game from "./components/dealer/dashboard";
import VerifyEmail from "./components/signup/verify-email";
import Forgot from "./components/forgot/forgot";
import ResetPassword from "./components/resetPassword/ResetPassword";
import UserContext from "./context/UserContext";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import NotFound from "./components/NotFound/NotFound";
import UserDashboard from "./components/User/UserDashboard";
import IntroVideo from "./components/introVideo";
import { getAllGameEvents, getUser } from "./utils/Api";
import loadericon from "./assets/images/dealer/loader.json";
import WaitingRoom from "./components/User/waitingroom";
import RequestAccess from "./components/requestAccess/requestAccess";
import "./assets/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import WentWrong from "./components/AccessDenied/WentWrong";
import { groupItems } from "./utils";
import NewGameView from "./components/User/playerGameView/newGameView";
import "./components/barRoom/style.css";
import "./components/barRoom/settings.css";
import SpectatorsWaitingRoom from "./components/spectators/spectatorsWaitingRoom";
import UserManagement from "./components/admin/userManagement";
import useResponsiveScale from "./utils/useResponsiveScale";
import {
  dataDogAppID,
  dataDogClientToken,
  dataDogEnv,
  ddEnable,
  server,
} from "./config/keys";
import CheckGameEvent from "./components/checkGameEvent";

import IntroVideoView from "./components/User/playerGameView/introVideoView";
import Confirmation from "./components/signup/confirmation";
import HostBroadcast from "./components/broadcast";

// set it to close video of players if the ping is high
const pingLimit = 2000;

function PrivateRoute({ component: RouteComponent, roles }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const { gameId } = useParams();

  const handleDataDogRUM = () => {
    const isInIt = datadogRum.getInternalContext();
    if (!isInIt) {
      datadogRum.init({
        applicationId: dataDogAppID,
        clientToken: dataDogClientToken,
        site: "datadoghq.com",
        service: "cloudpokernight",
        env: dataDogEnv,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [(url) => url.startsWith(server)],
      });
    }
  };
  if (ddEnable === "true") {
    handleDataDogRUM();
    const DDUser = datadogRum.getUser();
    if (user && !DDUser.name) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
      });
    }
  }

  const userHasRequiredRole = !!(user && roles.includes(user.role));

  if (user && !userHasRequiredRole) {
    return <AccessDenied />;
  }

  if (user && gameId?.split(" ")?.length > 1) {
    return <Navigate to="*" />;
  }

  if (user && user.role === "admin" && location.pathname === "/") {
    return <Navigate to="/dealer" />;
  }
  if (user && location.pathname === "/") {
    return <Navigate to={`/${user.role}`} />;
  }

  if (userHasRequiredRole) {
    return RouteComponent;
  }
  const storedRedirectLink = localStorage.getItem("redirectLink");
  const redirect = storedRedirectLink
    ? storedRedirectLink !== "/"
      ? storedRedirectLink
      : location.pathname
    : location.pathname;
  //  const redirect = location.pathname;
  if (redirect) {
    // console.log("redirect PrivateRoute", redirect);
    localStorage.setItem("redirectLink", redirect);
    return <Navigate to={`/signup?redirect=${redirect}`} />;
  }
  return <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  component: PropTypes.element,
  roles: PropTypes.array.isRequired,
};

PrivateRoute.defaultProps = {
  component: <>g</>,
};

const LoaderAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadericon,
};

function App() {
  const [user, setUser] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [callObject, setCallObject] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 14))
  );
  const [messages, setMessages] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [gameData, setGameData] = useState({});
  const [livePlayers, setLivePlayers] = useState([]);
  const [gamePlayers, setGamePlayers] = useState([]);
  const [gameAllPlayers, setAllGamePlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);
  const [gameEvent, setGameEvent] = useState(null);
  const [speaker, setSpeaker] = useState(
    JSON.parse(localStorage.getItem("speakerVolume")) ?? true
  );

  const [oneToOneRoom, setOneToOneRoom] = useState([]);
  const [barroomVolume, setBarroomVolume] = useState(
    localStorage.getItem("barRoomBackgroundSound")
      ? Number(localStorage.getItem("barRoomBackgroundSound"))
      : 0.6
  );

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [historySidebar, setHistorySidebar] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [oneToOneDuration, setOneToOneDuration] = useState("00:00");
  const [games, setGames] = useState([]);
  const [tokens, setTokens] = useState(
    JSON.parse(localStorage.getItem("token")) ?? {}
  );

  const [pokerTableVolume, setPokerTableVolume] = useState(
    localStorage.getItem("tableConversationsSound")
      ? Number(localStorage.getItem("tableConversationsSound"))
      : 0.6
  );

  const [noiseCancellation, setNoiseCancellation] = useState(
    JSON.parse(localStorage.getItem("noise-off")) ?? false
  );

  const [blurBackGround, setBlurBackGround] = useState("background-image");
  const [bgState, setBgState] = useState(true);
  const [userMic, setUserMic] = useState(
    JSON.parse(localStorage.getItem("mic")) ?? { value: "default" }
  );

  const [userCamera, setUserCamera] = useState(
    JSON.parse(localStorage.getItem("camera")) ?? {}
  );

  const [userSpeaker, setUserSpeaker] = useState(
    JSON.parse(localStorage.getItem("speaker")) ?? { value: "default" }
  );

  const [allGamesData, setAllGamesData] = useState({});
  const [micOn, setMicOn] = useState(
    JSON.parse(localStorage.getItem("isMicOn")) ?? true
  );

  const [camOn, setCamOn] = useState(
    JSON.parse(localStorage.getItem("isCameraOn")) ?? true
  );

  const [initialZoom, setInitialZoom] = useState(
    localStorage.getItem("communityVideoZoom")
      ? Number(localStorage.getItem("communityVideoZoom"))
      : 1
  );

  const [micValue, setMicValue] = useState(
    localStorage.getItem("outputVolume") ?? "60"
  );

  const [musicVolume, setMusicVolume] = useState(
    localStorage.getItem("musicVolume") ?? 25
  );

  const [showProfileInfo, setShowProfileInfo] = useState("");
  const [isRecording, setIsRecording] = useState(true);
  const [reactions, setReactions] = useState([]);
  const [values, setValues] = useState(0);
  const [showDrop, setShowDrop] = useState("");
  const [feedMusicPlayer, setFeedMusicPlayer] = useState(null);
  const [responsiveScale, setResponsiveScale] = useState({});
  const [scanCard, setScanCard] = useState(false);
  const [latencyMs, setLatencyMs] = useState(0);
  const [showCountDown, setShowCountDown] = useState(false);
  const [mirrorVideo, setMirrorVideo] = useState(true);
  const [showBroadcastControl, setShowBroadcastControl] = useState(false);
  const [showIntroVideo, setShowIntroVideo] = useState(false);

  const state = useResponsiveScale();
  useEffect(() => {
    setResponsiveScale(state);
  }, [state]);

  const getUserCallback = (data) => {
    setIsLoading(false);
    if (data.success) {
      setUser(data.user);
    }
  };

  useEffect(() => {
    getUser(getUserCallback);
  }, []);

  const fetchGames = async (
    sKey = "",
    sDate = new Date(),
    eDate = new Date(new Date().setDate(new Date().getDate() + 14))
  ) => {
    const res = await getAllGameEvents(
      sDate.toISOString(),
      eDate.toISOString(),
      sKey
    );
    const groups = groupItems(res, "scheduleDate");
    setGames(groups);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        tokens,
        setTokens,
        livePlayers,
        setLivePlayers,
        gameData,
        messages,
        setMessages,
        setGameData,
        setParticipants,
        oneToOneDuration,
        setOneToOneDuration,
        participants,
        gamePlayers,
        setGamePlayers,
        gameAllPlayers,
        setAllGamePlayers,
        showProfileModal,
        setOneToOneRoom,
        oneToOneRoom,
        setShowProfileModal,
        setCallObject,
        callObject,
        barroomVolume,
        setBarroomVolume,
        pokerTableVolume,
        setPokerTableVolume,
        noiseCancellation,
        setNoiseCancellation,
        historySidebar,
        setHistorySidebar,
        deviceStatus,
        setDeviceStatus,
        setGameEvent,
        gameEvent,
        games,
        setGames,
        fetchGames,
        startDate,
        endDate,
        searchKey,
        setSearchKey,
        setStartDate,
        setEndDate,
        blurBackGround,
        setBlurBackGround,
        bgState,
        setBgState,
        userMic,
        setUserMic,
        userCamera,
        setUserCamera,
        userSpeaker,
        setUserSpeaker,
        setSpeaker,
        speaker,
        setAllGamesData,
        allGamesData,
        micOn,
        setMicOn,
        camOn,
        setCamOn,
        initialZoom,
        setInitialZoom,
        isPlayerLoading,
        setIsPlayerLoading,
        micValue,
        setMicValue,
        showProfileInfo,
        setShowProfileInfo,
        isRecording,
        setIsRecording,
        reactions,
        setReactions,
        values,
        setValues,
        musicVolume,
        setMusicVolume,
        showDrop,
        setShowDrop,
        feedMusicPlayer,
        setFeedMusicPlayer,
        responsiveScale,
        scanCard,
        setScanCard,
        latencyMs,
        setLatencyMs,
        pingLimit,
        showCountDown,
        setShowCountDown,
        mirrorVideo,
        setMirrorVideo,
        setShowBroadcastControl,
        showBroadcastControl,
        showIntroVideo,
        setShowIntroVideo,
      }}
    >
      {isLoading ? (
        <div className="loader">
          <Lottie options={LoaderAnimation} />
        </div>
      ) : (
        <div className="App">
          <Router>
            <Routes>
              {/* Dealer routes starts */}
              <Route
                exact
                path="/dealer"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<Game />}
                  />
                }
              />
              <Route
                exact
                path="/dealer/companion/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<DealerCompanion />}
                  />
                }
              />

              <Route
                exact
                path="/dealer/game/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin", "user"]}
                    component={<NewGameView />}
                  />
                }
              />

              <Route
                exact
                path="/user/intro-room/:gameId"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<IntroVideoView />}
                  />
                }
              />

              {/* <Route
                exact
                path="/dealer/tournament/:gameId"
                element={<AutomatedPlayerGameView />}
              /> */}

              <Route
                exact
                path="/dealer/game-community-card/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<NewGameView />}
                  />
                }
              />

              <Route
                exact
                path="dealer/request-access"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<RequestAccess />}
                  />
                }
              />

              {/* Dealer routes end */}

              {/* User routes starts */}

              <Route
                exact
                path="/user"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<UserDashboard />}
                  />
                }
              />

              {/* <Route
                exact
                path="/user/profile"
                element={
                  <PrivateRoute
                    roles={["user", "dealer"]}
                    component={<CreateProfile />}
                  />
                }
              /> */}
              <Route
                exact
                path="/user/waiting-room/:gameId"
                element={
                  <PrivateRoute
                    roles={["user", "dealer", "admin"]}
                    component={<WaitingRoom />}
                  />
                }
              />

              <Route
                exact
                path="/user/main-game/:gameId"
                element={
                  <PrivateRoute roles={["user"]} component={<NewGameView />} />
                }
              />
              {/* <Route
                exact
                path="/user/tournament/:gameId"
                element={<AutomatedPlayerGameView />}
              /> */}
              {/* <Route
                exact
                path="/user/barroom/:gameId"
                element={
                  <PrivateRoute roles={["user"]} component={<BarRoom />} />
                }
              /> */}
              <Route
                exact
                path="/intro-video"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<IntroVideo />}
                  />
                }
              />
              <Route
                exact
                path="/user-management"
                element={
                  <PrivateRoute
                    roles={["admin"]}
                    component={<UserManagement />}
                  />
                }
              />
              <Route
                exact
                path="/user/spectators/:gameId"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<SpectatorsWaitingRoom />}
                  />
                }
              />
              <Route
                exact
                path="/check-game/:eventId"
                element={
                  <PrivateRoute
                    roles={["user", "dealer", "admin"]}
                    component={<CheckGameEvent />}
                  />
                }
              />
              {/* User routes end */}

              {/* Public routes start */}
              <Route
                exact
                path="/"
                element={<PrivateRoute roles={["dealer", "user", "admin"]} />}
              />
              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/confirmation" element={<Confirmation />} />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/profile"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<LinkedInLoginView />}
                  />
                }
              />
              <Route
                exact
                path="/user/video-setting/:gameId"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<VideoSettings />}
                  />
                }
              />
              <Route exact path="/verify-email" element={<VerifyEmail />} />
              <Route exact path="/forgot" element={<Forgot />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/went-wrong" element={<WentWrong />} />
              <Route exact path="/host-broadcast" element={<HostBroadcast />} />
              {/* <Route exact path="/waiting" element={<SpectatorsWaitingRoom />} /> */}
              <Route exact path="*" element={<NotFound />} />
              {/* Public routes end */}
            </Routes>
          </Router>
        </div>
      )}
    </UserContext.Provider>
  );
}

export default App;
