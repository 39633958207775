/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import WaitingPlayerRow from "./waitingPlayerRow";

function TableView({ gameEvent }) {
  return (
    <div className="table-wrapper">
      <div className="table-header">
        <h4>Other tables</h4>
      </div>
      <div className="table-content">
        {/* {[...Array(5).keys()].map((elem) => (
          <div className="table-line">
            <div className="table-number">
              <span>Table</span>
              <span>
                #
                {elem + 1}
              </span>
            </div>
            <div className="table-profile-wrapper">
              {[...Array(7).keys()].map(() => (
                <div
                  className="profile"
                  style={{ backgroundColor: getRandomColor() }}
                >
                  <div className="profile-icon">EP</div>
                  <div className="status-badge" />
                </div>
              ))}
            </div>
          </div>
        ))} */}

        {gameEvent?.games?.map((gameData) => (
          <div className="table-line" key={gameData?.id}>
            <div className="table-number">
              <span>Table</span>
              <span>
                {gameData?.tableNumber ? ` #${gameData?.tableNumber}` : ""}
                {" "}
              </span>
            </div>
            <div className="table-profile-wrapper">
              {Array.isArray(gameData?.waitingPlayers)
                && gameData?.waitingPlayers?.map((player) => (
                  <WaitingPlayerRow player={player} seeMore={false} />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

TableView.defaultProps = {
  gameEvent: [],
};

TableView.propTypes = {
  gameEvent: PropTypes.array,
};

export default TableView;
