/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  useActiveSpeakerId,
  useAudioTrack,
  useDaily,
  useDevices,
  useVideoTrack,
} from "@daily-co/daily-react";
import UserContext from "../../../context/UserContext";
import MuteIcon from "../../../IconComponents/MuteIcon";

function HostControlUser({ hostData }) {
  const { user } = useContext(UserContext);
  return (
    <div className="rail-slider-content">
      <OneToOneUserList
        player={!hostData?.isBroadcasting ? user : hostData.hostUserData}
      />
    </div>
  );
}

HostControlUser.defaultProps = {
  hostData: {},
};

HostControlUser.propTypes = {
  hostData: PropTypes.object,
};

export default HostControlUser;

function OneToOneUserList({ player }) {
  const { user, speaker, mirrorVideo } = useContext(UserContext);
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();
  const activeSpeakerId = useActiveSpeakerId();

  const isMirrorUser = user?.id === player?.id && mirrorVideo;
  const { hasCamError } = devices || {};

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  useEffect(() => {
    if (videoTrack.persistentTrack && videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (audioTrack.persistentTrack && audioRef?.current) {
      //  const isParticipantInCall = oneToOneRoom.find((room) =>
      //    room.roomId.includes(player.id)
      //  );
      //  const isUserInCall = oneToOneRoom.some((room) =>
      //    room.roomId.includes(user.id)
      //  );
      //  audioRef.current.volume =
      //    isParticipantInCall && isParticipantInCall.roomId?.includes(user.id)
      //      ? 1.0
      //      : isParticipantInCall || isUserInCall
      //      ? 0.0
      //      : pokerTableVolume;
      //  audioRef.current.srcObject = new MediaStream([
      //    audioTrack.persistentTrack,
      //  ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    audioTrack.subscribed,
  ]);
  const activeSpeaker = useMemo(
    () => activeSpeakerId === remoteParticipant?.session_id && player?.isAudio,
    [activeSpeakerId, remoteParticipant?.session_id, player?.isAudio]
  );
  return (
    <div
      className={`rail-player-wrapper  ${activeSpeaker ? "player-voice" : ""}`}
    >
      <div className="player-box-iframe full-height">
        {remoteParticipant
        && remoteParticipant?.video
        && (!videoTrack?.isOff || !hasCamError)
        && videoTrack?.state === "playable" ? (
          <video
            style={{
              width: "100%",
              height: "100%",
              transform: isMirrorUser ? "scale(-1, 1)" : "",
              filter: isMirrorUser ? "FlipH" : "",
            }}
            ref={videoRef}
            autoPlay
            controls={false}
            preload=""
            muted
          />
          ) : (
            <div className="main-player-camoff">
              <img
                src={player?.avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                }}
                alt=""
              />
            </div>
          )}
        {remoteParticipant?.user_name !== user?.id ? (
          <audio
            ref={audioRef}
            autoPlay
            muted={remoteParticipant?.local || !speaker}
            id={player.id}
          />
        ) : (
          ""
        )}
      </div>
      {/* Already Implemented */}
      <div className="rail-username">
        <span title={player?.name}>
          {player?.name}
          {" "}
          {audioTrack?.state === "playable" ? "" : <MuteIcon />}
        </span>
      </div>
    </div>
  );
}

OneToOneUserList.defaultProps = {
  player: {},
};

OneToOneUserList.propTypes = {
  player: PropTypes.object,
};
