import React, { useContext, useEffect, useRef, useState } from "react";
import PropsTypes from "prop-types";
// import Form from "react-bootstrap/Form";
import { useDaily, useDevices } from "@daily-co/daily-react";
import { useParams } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import ArrowIcon from "../../../IconComponents/ArrowIcon";
import UserContext from "../../../context/UserContext";
import MicIcon from "../../../IconComponents/MicIcon";
import MicOffNewIcon from "../../../IconComponents/MicOffNewIcon";
import MicFilledActiveIcon from "../../../IconComponents/MicFilledActiveIcon";
import ActiveTickIcon from "../../../IconComponents/ActiveTickIcon";
// import DropdownIcon from "../../../IconComponents/DropdownIcon";
import MicActiveIcon from "../../../IconComponents/MicActiveIcon";
import SpeakerNewIcon from "../../../IconComponents/SpeakerNewIcon";
import socket from "../../../config/socket";
import useOutsideAlerter from "../../../utils/outSideAlert";
import Microphone from "../../videoSettings/Microphone";
import arrow from "../../../assets/images/arrow-up.png";

function MicSetting({ handleSetting }) {
  const {
    user,
    micOn,
    showDrop,
    // allGamesData,
    userMic,
    setUserMic,
    setMessages,
    // setMicOn,
    setShowDrop,
    gameEvent,
    // speaker,
    // setSpeaker,
    userSpeaker,
    setUserSpeaker,
    feedMusicPlayer,
    pokerTableVolume,
    setPokerTableVolume,
    // barroomVolume
  } = useContext(UserContext);

  const [microphoneOptions, setMicrophoneOptions] = useState([]);
  const [speakerOptions, setSpeakerOptions] = useState([]);
  const [showMicSpeakerDropper, setShowMicSpeakerDropper] = useState(false);
  const micDropRef = useRef(null);
  const selectRef = useRef(null);
  const { gameId } = useParams();
  //  const gameData = allGamesData[gameId];
  const call = useDevices();
  const callObject = useDaily();
  const remoteParticipant = callObject?.participants()?.local || {};
  const currentDevice = callObject?.currentSpeaker?.device;

  useOutsideAlerter((showDrop === "mic" || showDrop === "speaker") ? micDropRef : "", setShowDrop);
  useOutsideAlerter(
    showMicSpeakerDropper ? selectRef : "",
    setShowMicSpeakerDropper
  );

  const handleUserMic = (value) => {
    setUserMic(value);
    localStorage.setItem("mic", JSON.stringify(value));
    setShowDrop("");
  };

  const handleMic = () => {
    if (
      gameEvent?.isBroadcasting
      && user?.email.toLowerCase() !== gameEvent?.host.toLowerCase()
    ) {
      setMessages((old) => [
        ...old,
        {
          msg: "Host Broadcasting.",
          id: `micDeviceBlock-${user?.id}`,
          type: "error",
        },
      ]);
    } else {
      if (call.hasMicError) {
        setMessages((old) => [
          ...old,
          {
            msg: "Please enable mic permission or device not found",
            id: `micDeviceBlock-${user?.id}`,
            type: "error",
          },
        ]);
        datadogRum.addAction("Mic Action", {
          msg: "Please enable mic permission or device not found",
          isAudio: remoteParticipant.video,
        });
        return;
      }
      socket.emit("playerMicChange", {
        gameId,
        userId: user?.id,
        isAudio: !remoteParticipant.audio,
      });
      datadogRum.addAction("Mic Action", {
        msg: "Mic state change",
        isAudio: !remoteParticipant.audio,
      });
    }
  };

  useEffect(() => {
    if (userSpeaker) {
      call.setSpeaker(userSpeaker?.value);
    }
  }, [userSpeaker]);

  //  const handleSpeaker = () => {
  //    setSpeaker(!speaker);
  //    localStorage.setItem("speakerVolume", !speaker);
  //    if (user?.role === "dealer" || user?.role === "admin") {
  //      socket.emit("handleDealerSpeakerMute", { gameId, status: speaker });
  //    }
  //  };

  //  useEffect(() => {
  //    const shouldHandleSpeaker = (gameData?.viewers.length > 0
  //        && speaker
  //        && pokerTableVolume === 0
  //        && barroomVolume === 0)
  //      || (gameData?.viewers.length > 0
  //        && !speaker
  //        && (pokerTableVolume > 0 || barroomVolume > 0))
  //      || (gameData?.viewers.length === 0 && speaker && pokerTableVolume === 0)
  //      || (gameData?.viewers.length === 0 && !speaker && pokerTableVolume > 0);

  //    if (shouldHandleSpeaker) {
  //      handleSpeaker();
  //    }
  //  }, [pokerTableVolume, barroomVolume, gameData?.viewers?.length]);

  useEffect(() => {
    let isMounted = true;
    let streamAudio;
    const getMediaDevices = async () => {
      try {
        const allDevices = await navigator.mediaDevices.enumerateDevices();

        const filterDevices = (kind) =>
          allDevices.filter((m) => m.kind === kind);

        setMicrophoneOptions(
          filterDevices("audioinput").map((mic) => ({
            label: mic.label,
            value: mic.deviceId,
          }))
        );
        if (
          (!speakerOptions?.length || !speakerOptions[0]?.label)
          && !call?.currentSpeaker
        ) {
          setMessages((old) => [
            ...old,
            {
              msg: "Please allow Media device permission",
              id: "SpeakerDeviceBlock",
              type: "error",
            },
          ]);
          streamAudio = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
        }

        if (isMounted) {
          setSpeakerOptions(
            filterDevices("audiooutput").map((s) => ({
              label: s.label,
              value: s.deviceId,
            }))
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error while fetching media devices:", error);
      }
    };

    if (showDrop) getMediaDevices();
    return () => {
      isMounted = false;

      if (streamAudio) {
        streamAudio.getTracks().forEach((track) => track.stop());
      }
    };
  }, [showDrop]);

  //  useEffect(() => {
  //    if (userMic) {
  //      call.setMicrophone(userMic?.value);
  //    }
  //  }, [userMic]);

  useEffect(() => {
    socket.on("dealerMuteAllUser", (data) => {
      if (data?.gameId === gameId) {
        const selfPlayer = data?.game?.players?.find(
          (pl) => pl?.id === user?.id
        );
        if (selfPlayer) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
          datadogRum.addAction("Mic Action", {
            msg: "Mic state change by Dealer muteAll",
            isAudio: false,
          });
        }
      }
    });
  }, []);
  useEffect(() => {
    socket.on("muteAllWaitingUser", (data) => {
      if (data?.gameId === gameId) {
        const selfPlayer = data?.game?.waitingPlayers?.find(
          (pl) => pl?.id === user?.id
        );
        const isHost = data?.gameEvent?.host.toLowerCase()
          === selfPlayer?.email.toLowerCase();
        if (selfPlayer && !isHost) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: selfPlayer.isAudio,
          });
          datadogRum.addAction("Mic Action", {
            msg: "Mic state change by Dealer Broadcasting",
            isAudio: false,
          });
          setMessages((old) => [
            ...old,
            {
              msg: "Your mic is muted due to the host broadcasting.",
              id: `micDeviceBlock-${user?.id}`,
              type: "error",
            },
          ]);
        }
      }
    });
  }, []);

  const handleShowMicSpeakerDropper = () => {
    setShowMicSpeakerDropper(!showMicSpeakerDropper);
    setShowDrop("");
  };

  const speakerValue = JSON.parse(localStorage.getItem("speaker"))
    ? JSON.parse(localStorage.getItem("speaker"))
    : speakerOptions || speakerOptions[0];

  const micValue = JSON.parse(localStorage.getItem("mic"))
    ? JSON.parse(localStorage.getItem("mic"))
    : microphoneOptions || microphoneOptions[0];

  //  console.log("microphoneOptions =", microphoneOptions);

  const handleSpeakerChange = async (value) => {
    setUserSpeaker(value);
    localStorage.setItem("speaker", JSON.stringify(value));
    setShowDrop("");

    if (feedMusicPlayer) {
      await feedMusicPlayer?.setSinkId(value?.value || "");
    }
  };

  const handlePokerTableVolume = (value) => {
    localStorage.setItem("tableConversationsSound", value / 100);
    setPokerTableVolume(value / 100);
  };

  const renderVolumeControl = (title, value, setValue, level) => (
    <div className="volume-control-wrapper">
      <div className="slider-title">
        <span>{title}</span>
      </div>
      <div className="volume-range-main">
        <input
          className="volume-input-slider"
          type="range"
          min="0"
          max="100"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id="slider"
        />
        <div
          id="selector"
          style={{
            left: `${
              level <= 7
                ? 7
                : level
            }%`,
          }}
        >
          <div
            className="volume-range-selectBtn"
            style={{ left: `${level <= 12 ? 0 : level >= 96 ? 0 : 12}px` }}
          />
          <div
            className="selectValue"
            style={{ left: `${level <= 12 ? 50 : level >= 96 ? 50 : 100}%` }}
          >
            {level}
            %
          </div>
        </div>
        <div id="progressBar" style={{ width: `${level}%` }} />
      </div>
    </div>
  );

  const renderSpeakerOptions = () =>
    speakerOptions?.map((sp) => (
    //  <div className="drop-item" key={sp?.value}>
    //    <div className="invite-user-action">
    //      <Form.Check
    //        className="add-check-box"
    //        type="radio"
    //        id={sp.value}
    //        name="speaker-action"
    //        label={sp.label}
    //        value={sp.value}
    //        checked={
    //          userSpeaker?.value === sp.value
    //          || currentDevice?.deviceId === sp.value
    //        }
    //        onChange={() => handleSpeakerChange(sp)}
    //      />
    //    </div>
    //  </div>
      <div
        className={`option ${
          sp.value === userSpeaker?.value
          || (currentDevice?.deviceId === sp.value && !userSpeaker)
            ? "active"
            : ""
        }`}
        onClick={() => handleSpeakerChange(sp)}
        // onKeyDown={(e) =>
        //  e.key === "Enter" && handleOptionSelect(option.value)}
        role="presentation"
        key={sp?.label}
      >
        {(sp.value === userSpeaker?.value
          || (currentDevice?.deviceId === sp.value && !userSpeaker)) && (
          <span className="active-tick mic">
            <ActiveTickIcon />
          </span>
        )}
        {sp.label}
        {sp?.value === "default" && (
        <span className="sublabel">System default</span>
        )}
      </div>
    ));

  const renderMicrophoneOptions = () =>
    microphoneOptions?.map((mic) => (
      //  <div className="drop-item" key={mic.label}>
      //    <div className="invite-user-action">
      //      <Form.Check
      //        className="add-check-box"
      //        type="radio"
      //        id={mic.label}
      //        name="mic-action"
      //        label={mic.label}
      //        value={mic.label}
      //        checked={
      //          mic.value === userMic?.value
      //          || (currentDevice?.deviceId === mic.value && !userMic)
      //        }
      //        onChange={() => handleUserMic(mic)}
      //      />
      //    </div>
      //  </div>
      <div
        className={`option ${
          mic.value === userMic?.value
          || (currentDevice?.deviceId === mic.value && !userMic)
            ? "active"
            : ""
        }`}
        onClick={() => handleUserMic(mic)}
        // onKeyDown={(e) =>
        //  e.key === "Enter" && handleOptionSelect(option.value)}
        role="presentation"
        key={mic?.label}
      >
        {(mic.value === userMic?.value
          || (currentDevice?.deviceId === mic.value && !userMic)) && (
          <span className="active-tick mic">
            <ActiveTickIcon />
          </span>
        )}
        {mic.label}
        {mic?.value === "default" && (
          <span className="sublabel">System default</span>
        )}
      </div>
    ));

  return (
    <div className="setting-btn-group mic-btn-wrapper" ref={selectRef}>
      <span className={`setting-btn ${showMicSpeakerDropper ? "active" : ""}`}>
        {/* <span onClick={handleMic} role="presentation"> */}
        <span
          className={`btn-down-icon ${showMicSpeakerDropper ? "rotate" : ""}`}
          //  onClick={() => handleSetting("mic")}
          onClick={handleShowMicSpeakerDropper}
          role="presentation"
        >
          <ArrowIcon />
        </span>
        {JSON.parse(localStorage.getItem("isMicOn")) ?? micOn ? (
          <span className="mic-icon" onClick={handleMic} role="presentation">
            <MicIcon />
          </span>
        ) : (
          <span
            className="mic-icon off"
            onClick={handleMic}
            role="presentation"
          >
            <MicOffNewIcon />
          </span>
        )}
        {/* </span> */}
      </span>
      <div
        className={`drop-wrapper ${
          showMicSpeakerDropper ? "show" : "hide"
        } action-dropper`}
        ref={micDropRef}
      >
        <div className="mic-speaker-container">
          <button
            className="select-btn"
            // ref={micDropRef}
            onClick={() => handleSetting("mic")}
            type="button"
          >
            <MicActiveIcon />
            <span className="select-btn-text waiting-room">{micValue?.label || "..."}</span>
            <span className="select-btn-icon">
              <img src={arrow} alt="arrow" />
            </span>
          </button>
          <button
            className="select-btn"
            // ref={micDropRef}
            onClick={() => handleSetting("speaker")}
            type="button"
          >
            <SpeakerNewIcon />
            <span className="select-btn-text waiting-room">{speakerValue?.label || "..."}</span>
            <span className="select-btn-icon">
              <img src={arrow} alt="arrow" />
            </span>
          </button>
        </div>
        <div
          className={`drop-wrapper ${
            showDrop === "mic" ? "show" : "hide"
          } action-dropper`}
        >
          {renderMicrophoneOptions()}
          {showDrop === "mic" && (
            <div className="mic-component">
              <MicFilledActiveIcon />
              <Microphone />
            </div>
          )}
        </div>
        <div
          className={`drop-wrapper ${showDrop === "speaker" ? "show" : "hide"} action-dropper`}
        >
          {renderSpeakerOptions()}
          <div className="speaker-item-divider" />
          {renderVolumeControl(
            "Main game volume",
            parseInt(pokerTableVolume * 100, 10),
            handlePokerTableVolume,
            parseInt(pokerTableVolume * 100, 10)
          )}
        </div>
      </div>
    </div>
  );
}

MicSetting.propTypes = {
  handleSetting: PropsTypes.func.isRequired,
};

export default MicSetting;
