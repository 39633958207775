/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext } from "react";
import PropsTypes from "prop-types";
import {
  useDaily,
  useVideoTrack,
  useAudioTrack,
  useDevices,
} from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import socket from "../../config/socket";
import UserContext from "../../context/UserContext";
import EndCallIcon from "../../assets/images/toast/close.svg";
import MicOffIcon from "../../IconComponents/MicOffIcon";
import CamOffIcon from "../../IconComponents/CamOffIcon";

function Participant({ player, gameData, isExpended }) {
  const { user, oneToOneRoom, barroomVolume, speaker, mirrorVideo } = useContext(UserContext);
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();

  const { hasCamError } = devices || {};

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);
  const handleEndCall = () => {
    socket.emit("oneToOneLeaveRequest", {
      gameId: gameData.id,
      roomId: gameData?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
  };

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(player.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      if (isParticipantInCall) {
        if (isParticipantInCall.roomId.includes(user.id)) {
          audioRef.current.volume = 1.0;
        } else {
          audioRef.current.volume = 0.0;
        }
      } else if (isUserInCall) {
        audioRef.current.volume = 0.0;
      } else {
        audioRef.current.volume = barroomVolume;
      }

      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    barroomVolume,
    audioTrack.subscribed,
  ]);
  const isMirrorUser = user?.id === player.id && mirrorVideo;

  return (
    <>
      {isExpended ? (
        <img
          src={player?.avatar}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null; // prevents looping
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
          }}
          alt=""
        />
      ) : (
        <>
          <div className="player-box-iframe ">
            {remoteParticipant
            && audioTrack.state
            && (!videoTrack.isOff || !hasCamError)
            && videoTrack.state === "playable" ? (
              <video
                style={{
                  width: "100%",
                  transform: isMirrorUser ? "scale(-1, 1)" : "",
                  filter: isMirrorUser ? "FlipH" : "",
                }}
                ref={videoRef}
                autoPlay
                controls={false}
                preload=""
                muted
              />
              ) : (
                <div className="main-player-camoff">
                  <img
                    src={player?.avatar}
                    onError={({ currentTarget }) => {
                    // eslint-disable-next-line no-param-reassign
                      currentTarget.onerror = null; // prevents looping
                      // eslint-disable-next-line no-param-reassign
                      currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                    }}
                    alt=""
                  />
                </div>
              )}
            {oneToOneRoom?.find((room) => room.roomId?.includes(player.id))
            && user.id === player.id ? (
              <div className="end-private-call">
                <Button className="rail-end-call" onClick={handleEndCall}>
                  <img src={EndCallIcon} alt="close icon" />
                </Button>
              </div>
              ) : (
                ""
              )}
          </div>
          <div className="rail-username">
            <span
              title={player.name}
              style={{
                paddingRight: `${
                  audioTrack?.state !== "playable"
                  && videoTrack?.state !== "playable"
                    ? "24px"
                    : audioTrack?.state !== "playable"
                      || videoTrack?.state !== "playable"
                      ? "12px"
                      : "0px"
                }`,
              }}
            >
              {player.name}
              <div className="cam-mic-wrapper">
                {audioTrack?.state !== "playable" ? <MicOffIcon /> : ""}
                {videoTrack?.state !== "playable" ? <CamOffIcon /> : ""}
              </div>
            </span>
          </div>
        </>
      )}
      {remoteParticipant?.user_name !== user?.id ? (
        <audio
          ref={audioRef}
          autoPlay
          muted={remoteParticipant?.local || !speaker}
          id={player.id}
        />
      ) : (
        ""
      )}
    </>
  );
}
Participant.defaultProps = {
  player: null,
  gameData: {},
  isExpended: false,
};

Participant.propTypes = {
  player: PropsTypes.object,
  gameData: PropsTypes.object,
  isExpended: PropsTypes.bool,
};

export default Participant;
