import React from "react";

function WifiPingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M9.84484 13.8726C10.1984 13.519 10.397 13.0396 10.397 12.5396C10.397 12.0397 10.1984 11.5602 9.84484 11.2067C9.49132 10.8531 9.01185 10.6545 8.5119 10.6545C8.01195 10.6545 7.53247 10.8531 7.17895 11.2067C6.82544 11.5602 6.62683 12.0397 6.62683 12.5396C6.62683 13.0396 6.82544 13.519 7.17895 13.8726C7.53247 14.2261 8.01195 14.4247 8.5119 14.4247C9.01185 14.4247 9.49132 14.2261 9.84484 13.8726Z"
        fill="#ffffff"
      />
      <path
        d="M11.9956 8.79964C12.0855 8.88552 12.1576 8.98836 12.2077 9.10224C12.2577 9.21612 12.2847 9.33878 12.2871 9.46314C12.2896 9.58751 12.2673 9.71112 12.2218 9.82686C12.1762 9.9426 12.1081 10.0482 12.0216 10.1375C11.935 10.2268 11.8316 10.2981 11.7173 10.3473C11.603 10.3964 11.4802 10.4225 11.3558 10.4239C11.2314 10.4254 11.108 10.4022 10.9926 10.3557C10.8772 10.3092 10.7722 10.2404 10.6835 10.1531C10.6528 10.1234 10.6208 10.0951 10.5874 10.0683C10.4089 9.93065 10.211 9.82013 10.0002 9.74028C9.57324 9.57063 9.05768 9.47638 8.51195 9.47638C7.65047 9.47638 6.87854 9.71295 6.43649 10.0692C6.40162 10.0975 6.36957 10.1248 6.34035 10.1531C6.25171 10.2404 6.14667 10.3092 6.03129 10.3557C5.91591 10.4022 5.79247 10.4254 5.66809 10.4239C5.54371 10.4225 5.42085 10.3964 5.30659 10.3473C5.19232 10.2981 5.08892 10.2268 5.00235 10.1375C4.91577 10.0482 4.84773 9.9426 4.80214 9.82686C4.75656 9.71112 4.73433 9.58751 4.73675 9.46314C4.73917 9.33878 4.76618 9.21612 4.81622 9.10224C4.86627 8.98836 4.93836 8.88552 5.02834 8.79964C5.09998 8.73083 5.17538 8.66391 5.25455 8.59982C6.05665 7.95607 7.24518 7.59131 8.51195 7.59131C9.29142 7.59131 10.0426 7.72892 10.6949 7.98717C11.1001 8.1474 11.463 8.35382 11.7693 8.59982C11.8485 8.66391 11.9239 8.73083 11.9956 8.79964Z"
        fill="#ffffff"
      />
      <path
        d="M14.3678 6.35585C14.4568 6.44363 14.5274 6.54823 14.5756 6.66355C14.6237 6.77888 14.6485 6.90263 14.6483 7.02761C14.6482 7.15259 14.6233 7.2763 14.5749 7.39154C14.5265 7.50677 14.4557 7.61124 14.3666 7.69886C14.2775 7.78648 14.1718 7.8555 14.0558 7.90191C13.9397 7.94832 13.8156 7.97118 13.6907 7.96917C13.5657 7.96716 13.4424 7.94032 13.3279 7.89021C13.2134 7.8401 13.11 7.76772 13.0238 7.67728C12.9303 7.58285 12.8322 7.49319 12.7297 7.40866C12.5031 7.22468 12.2613 7.0604 12.0068 6.9176C11.7257 6.7596 11.4319 6.6256 11.1283 6.51702C10.8038 6.39841 10.4708 6.30451 10.1321 6.23615C9.77866 6.16309 9.42056 6.1149 9.06041 6.09194C8.51327 6.056 7.96393 6.07431 7.4204 6.14661C7.06968 6.19405 6.72264 6.26554 6.38173 6.36056C6.0596 6.45178 5.74458 6.56645 5.43919 6.70364C5.15927 6.83169 4.8901 6.98204 4.63427 7.15323C4.51692 7.23268 4.40338 7.3176 4.29402 7.40772C4.19149 7.49254 4.09334 7.58251 3.99995 7.67728C3.91429 7.76996 3.8109 7.84449 3.6959 7.89646C3.5809 7.94842 3.45664 7.97677 3.33048 7.97981C3.20432 7.98285 3.07884 7.96053 2.96147 7.91416C2.8441 7.86779 2.73724 7.79833 2.64722 7.70989C2.5572 7.62145 2.48585 7.51583 2.43742 7.3993C2.38898 7.28277 2.36444 7.1577 2.36525 7.03151C2.36606 6.90531 2.39221 6.78057 2.44213 6.66467C2.49206 6.54877 2.56475 6.44407 2.65589 6.35679C2.79256 6.21824 2.93771 6.08534 3.09134 5.95716C3.56873 5.5671 4.09542 5.24164 4.65783 4.98918C5.04965 4.81027 5.45493 4.66246 5.86993 4.54713C6.29619 4.42678 6.73057 4.33732 7.16969 4.27945C7.61452 4.21898 8.06293 4.18875 8.51185 4.18897C8.96073 4.18922 9.40909 4.21913 9.85402 4.27851C10.2932 4.33759 10.7279 4.42703 11.1547 4.54619C11.5687 4.66358 11.9736 4.81133 12.3659 4.98823C12.743 5.16055 13.1055 5.36323 13.4498 5.59428C13.78 5.81752 14.0874 6.0726 14.3678 6.35585Z"
        fill="#ffffff"
      />
      <path
        d="M16.2831 4.03061C16.4417 4.21799 16.521 4.45987 16.504 4.70478C16.487 4.94968 16.3751 5.1783 16.1921 5.34198C16.0092 5.50567 15.7696 5.59151 15.5243 5.58124C15.279 5.57097 15.0474 5.46541 14.8787 5.287C14.7429 5.13557 14.5995 4.99118 14.4489 4.85438C14.2922 4.71151 14.1286 4.57625 13.9588 4.44909C13.5979 4.1822 13.2147 3.94665 12.8137 3.74502C12.387 3.53105 11.9431 3.35353 11.4866 3.21437C11.0115 3.06651 10.5255 2.95621 10.0332 2.88449C9.52952 2.81026 9.02103 2.77371 8.51193 2.77515C8.00285 2.77511 7.49443 2.81196 6.99068 2.88543C6.49862 2.9587 6.01278 3.06898 5.5373 3.21531C5.08189 3.35718 4.63845 3.53493 4.21115 3.7469C4.00693 3.84909 3.80724 3.9601 3.61264 4.07962C3.42489 4.19473 3.24248 4.31833 3.06597 4.45003C2.89619 4.57718 2.73263 4.71244 2.57586 4.85532C2.42497 4.9924 2.2812 5.13711 2.14512 5.28889C1.97768 5.47245 1.74453 5.58248 1.49638 5.59504C1.24824 5.60759 1.00518 5.52165 0.820064 5.35592C0.634952 5.19019 0.522768 4.95807 0.507913 4.71006C0.493059 4.46204 0.576736 4.21819 0.740743 4.03155C1.10277 3.6301 1.50303 3.26487 1.93588 2.94104C2.61807 2.4352 3.3633 2.02044 4.15271 1.70726C4.69852 1.48811 5.26116 1.31348 5.83514 1.1851C6.41919 1.05245 7.01251 0.964544 7.60993 0.92213C8.51065 0.856849 9.41581 0.890034 10.3093 1.0211C10.8972 1.10844 11.4775 1.24014 12.0455 1.41508C12.5991 1.58737 13.138 1.80358 13.6572 2.06165C14.1585 2.31313 14.6372 2.60735 15.088 2.94104C15.5223 3.26344 15.923 3.62879 16.2841 4.03155L16.2831 4.03061Z"
        fill="#ffffff"
      />
    </svg>
  );
}

export default WifiPingIcon;
