/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import PropsTypes from "prop-types";
import {
  useDaily,
  useDevices,
  useRecording,
  useScreenShare,
} from "@daily-co/daily-react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import SettingIcon from "../../../IconComponents/SettingIcon";
import {
  getGameRecordingById,
  startRecordingApi,
  stopRecordingApi,
} from "../../../utils/Api";
import CamSetting from "../../MediaDevices/camera";
import MicSetting from "../../MediaDevices/mic";
// import SpeakerSetting from "../../MediaDevices/speaker";
import BarRoomSetting from "../../barRoom/settings";
import intSignConvert from "../../../utils/intSignConvert";
import CloseIcon from "../../../IconComponents/CloseIcon";

let instanceId;

function OneToOneAction({ oneToOne }) {
  const {
    user,
    gameEvent,
    allGamesData,
    setMicOn,
    setCamOn,
    setMessages,
    isRecording,
    setIsRecording,
    showDrop,
    setShowDrop,
    responsiveScale,
  } = useContext(UserContext);

  const { gameId } = useParams();
  const gameData = allGamesData[gameId];
  const { players, viewers: viewerData } = gameData || {};
  const [showSetting, setShowSetting] = useState(false);
  const [setGameRecordingData] = useState({});
  const [showOneTwoOne, setShowOneTwoOne] = useState(false);
  //  Screen Scaling Function Start
  const call = useDevices();
  const callObject = useDaily();
  const remoteParticipant = callObject?.participants()?.local || {};
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getGameRecordingById(gameId);
        if (getData?.data) {
          setGameRecordingData(getData?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setGameRecordingData({});
      }
    };
    if (user?.role !== "user") {
      fetchData();
    }
  }, []);

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }

    if (oneToOne) {
      setShowOneTwoOne(!showOneTwoOne);
    }

    const errorMsg = name === "camera"
      ? call.cameraError?.msg || "Please enable camera permission"
      : name === "mic"
        ? "Please enable mic permission or device not found"
        : "";

    if (
      (errorMsg !== "Camera and Microphone in use by another application."
        && name === "camera"
        && (call.cameraError
          || call.hasCamError
          || remoteParticipant?.tracks?.video?.state === "blocked"))
      || (name === "mic" && call.hasMicError)
    ) {
      setMessages((old) => [
        ...old,
        { msg: errorMsg, id: `${name}DeviceBlock-${user?.id}`, type: "error" },
      ]);
      return;
    }

    if (name === "tables" && gameEvent?.games?.length <= 1) {
      return;
    }

    setShowDrop(name);
  };

  const {
    startRecording,
    stopRecording,
    isRecording: isDailyRecording,
  } = useRecording({
    onRecordingData: (data) => {
      console.log("recording data", data);
    },
    onRecordingStarted: (data) => {
      console.log("recording started", data);
    },
    onRecordingError: (error) => {
      console.log("recording error", error);
    },
    onRecordingStopped: (dd) => {
      console.log("recording stopped", dd);
      setIsRecording(false);
    },
  });

  const { startScreenShare, stopScreenShare, isSharingScreen } = useScreenShare(
    {
      onLocalScreenShareStarted: () => {
        console.log("local screen share started");
        startRecordingApi(gameId);
      },
      onLocalScreenShareStopped: () => {
        console.log("local stop screen share");
        stopRecordingApi(gameId);
      },
    }
  );

  useEffect(() => {
    if (
      (user?.role === "dealer" || user?.role === "admin")
      && remoteParticipant
      && startRecording
      && stopRecording
      && startScreenShare
      && stopScreenShare
    ) {
      //  if (!instanceId) {
      instanceId = crypto.randomUUID();
      //  }
      startRecording({ instanceId });
      if (isRecording && !isSharingScreen) {
        startScreenShare({
          displayMediaOptions: {
            selfBrowserSurface: "include",
            systemAudio: "include",
            surfaceSwitching: "exclude",
            audio: true,
          },
        });
      } else {
        if (isSharingScreen) {
          stopScreenShare();
        }
        if (isDailyRecording && instanceId) {
          stopRecording({ instanceId });
        }
      }
    }
    return () => {
      if (stopScreenShare && isSharingScreen) {
        stopScreenShare();
      }
      if (stopRecording && isDailyRecording) {
        stopRecording({ instanceId });
      }
    };
  }, [
    isRecording,
    startScreenShare,
    user?.role,
    startRecording,
    stopRecording,
    remoteParticipant.session_id,
  ]);

  useEffect(() => {
    if (gameEvent?.id) {
      socket.emit("getChats", { gameEventId: gameEvent.id });
    }
  }, [gameEvent?.id]);

  useEffect(() => {
    if (call.currentCam?.device) {
      const device = {
        label: call?.currentCam?.device?.label,
        value: call?.currentCam?.device?.deviceId,
      };
      localStorage.setItem("camera", JSON.stringify(device));
    }
  }, [call.currentCam?.device]);

  useEffect(() => {
    socket.on("dealerMuteAll", (data) => {
      if (data.gameId === gameId) {
        if (players?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("dealerMuteAllViewers", (data) => {
      if (data.gameId === gameId) {
        if (viewerData?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("playerMicChange", (data) => {
      if (data.userId === user.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setAudio: data.isAudio,
        });
        localStorage.setItem("isMicOn", data.isAudio);
        setMicOn(data.isAudio);
      }
    });

    socket.on("playerCameraChange", (data) => {
      if (data.userId === user?.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setVideo: data.isVideo,
        });
      }
    });

    return () => {
    //  socket.off("playerMicChange");
    //  socket.off("dealerMuteAll");
    //  socket.off("playerCameraChange");
    //  socket.off("dealerMuteAllViewers");
    };
  }, [remoteParticipant, callObject]);

  useEffect(() => {
    setCamOn(remoteParticipant.video);
    setMicOn(remoteParticipant.audio);
    if (remoteParticipant?.tracks?.video?.state === "blocked") {
      setCamOn(false);
    }
    if (remoteParticipant?.tracks?.audio?.state === "blocked") {
      setMicOn(false);
      if (gameData?.players?.some((pl) => pl?.id === user?.id)) {
        socket.emit("playerMicChange", {
          gameId,
          userId: user?.id,
          isAudio: false,
        });
      }
    }
  }, [remoteParticipant, remoteParticipant.audio, remoteParticipant.video]);

  const { scaleValue, topValue } = responsiveScale;

  const handleEndCall = () => {
    socket.emit("oneToOneLeaveRequest", {
      gameId: gameData.id,
      roomId: gameData?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
  };
  return (
    <>
      <div
        className={`side-setting-wrapper ${
          oneToOne ? "oneToOneShow" : ""
        }`}
      >

        {allGamesData[gameId]?.players?.find((spec) => spec.id === user?.id)
        || allGamesData[gameId]?.viewers?.find((spec) => spec.id === user?.id)
        || user?.role === "dealer"
        || user?.role === "admin" ? (
          <MicSetting handleSetting={handleSetting} />
          ) : (
            ""
          )}

        {allGamesData[gameId]?.players?.find((spec) => spec.id === user?.id)
        || allGamesData[gameId]?.viewers?.find((spec) => spec.id === user?.id)
        || user?.role === "dealer"
        || user?.role === "admin" ? (
          <CamSetting handleSetting={handleSetting} />
          ) : (
            ""
          )}
        {/* <SpeakerSetting
          isVolumeSlider
          isRailVolumeSlider
          handleSetting={handleSetting}
          showDrop={showDrop}
        /> */}

        <div className="setting-btn-group">
          <Button className="setting-btn" onClick={handleShowSetting}>
            <SettingIcon />
          </Button>
        </div>
        <div className="setting-btn-group">
          <Button className="close-btn" onClick={handleEndCall}>
            <CloseIcon />
          </Button>
        </div>
      </div>
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="bar-room-setting-popup main-game-setting"
        style={{
          transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
            scaleValue / 100
          })`,
        }}
        centered
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>
    </>
  );
}

OneToOneAction.defaultProps = {
  oneToOne: null,
};
OneToOneAction.propTypes = {
  oneToOne: PropsTypes.string,
};
export default OneToOneAction;
