import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropsTypes from "prop-types";
// import Form from "react-bootstrap/Form";
import { useDaily, useDevices } from "@daily-co/daily-react";
import { useParams } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import ArrowIcon from "../../../IconComponents/ArrowIcon";
import CameraIcon from "../../../IconComponents/CamIcon";
import CamOffNewIcon from "../../../IconComponents/CamOffNewIcon";
import UserContext from "../../../context/UserContext";
import socket from "../../../config/socket";
import useOutsideAlerter from "../../../utils/outSideAlert";
import ActiveTickIcon from "../../../IconComponents/ActiveTickIcon";

// let pingHigh = false;
function CamSetting({ handleSetting }) {
  const {
    user,
    camOn,
    userCamera,
    setUserCamera,
    latencyMs,
    pingLimit,
    setMessages,
    setCamOn,
    // showDrop,
    setShowDrop,
  } = useContext(UserContext);

  const camDropRef = useRef(null);
  const { gameId } = useParams();
  const call = useDevices();
  const callObject = useDaily();
  const [cameraOptions, setCameraOptions] = useState([]);
  const [showCamDrop, setShowCamDrop] = useState("");
  const [isCameraOn, setIsCameraOn] = useState(() =>
    JSON.parse(localStorage.getItem("isCameraOn"))
  );
  const remoteParticipant = callObject?.participants()?.local || {};
  const selectedCamera = call.cameras.find((cam) => cam.selected)?.device
    .deviceId;

  useOutsideAlerter(showCamDrop === "camera" ? camDropRef : "", setShowCamDrop);

  const handleCamera = () => {
    const isHighPing = Number(latencyMs) > pingLimit;

    // if (isHighPing) {
    //  const highPingMessage = "Due to high ping, video is auto closed";
    //  setMessages((old) => [
    //    ...old,
    //    {
    //      msg: highPingMessage,
    //      id: `highPing-${user?.id}`,
    //      type: "error",
    //    },
    //  ]);

    //  datadogRum.addAction("High Ping - Camera Action", {
    //    msg: highPingMessage,
    //    isVideo: remoteParticipant.video,
    //  });

    //  return;
    // }

    const cameraErrorMsg = call.cameraError?.msg
      !== "Camera and Microphone in use by another application."
      ? call.cameraError?.msg
      : null;

    if (
      cameraErrorMsg
      || call.hasCamError
      || remoteParticipant?.tracks?.video?.state === "blocked"
    ) {
      setMessages((old) => [
        ...old,
        {
          msg: cameraErrorMsg,
          id: `cameraDeviceBlock-${user?.id}`,
          type: "error",
        },
      ]);

      datadogRum.addAction("Camera Action", {
        msg: cameraErrorMsg,
        isVideo: remoteParticipant.video,
      });

      return;
    }

    const isVideo = !remoteParticipant.video;

    datadogRum.addAction("Camera Action", {
      isHighPing,
      isVideo,
    });

    setCamOn(isVideo);
    localStorage.setItem("isCameraOn", isVideo);
    socket.emit("playerCameraChange", {
      gameId,
      userId: user?.id,
      isVideo,
    });
  };

  const handleCameraChange = (value) => {
    setUserCamera(value);
    localStorage.setItem("camera", JSON.stringify(value));
    setShowCamDrop("");
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        const allDevices = await navigator.mediaDevices.enumerateDevices();

        const filterDevices = (kind) =>
          allDevices.filter((m) => m.kind === kind);

        setCameraOptions(
          filterDevices("videoinput").map((camera) => ({
            label: camera.label,
            value: camera.deviceId,
          }))
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error while fetching media devices:", error);
      }
    };

    if (showCamDrop) getMediaDevices();
  }, [showCamDrop]);

  useEffect(() => {
    if (userCamera) {
      call.setCamera(userCamera?.value);
    }
  }, [userCamera]);

  //  useEffect(() => {
  //    if (
  //      user?.role !== "user"
  //      || call.cameraError
  //      || call.hasCamError
  //      || remoteParticipant?.tracks?.video?.state === "blocked"
  //      || !JSON.parse(localStorage.getItem("isCameraOn"))
  //    ) {
  //      return;
  //    }

  //    if (latencyMs > pingLimit && !pingHigh) {
  //      callObject?.updateParticipant(remoteParticipant.session_id, {
  //        setVideo: false,
  //      });
  //      datadogRum.addAction("High Ping - Camera Action", {
  //        msg: "Due to high ping, video is auto closed",
  //        isVideo: false,
  //      });
  //      pingHigh = true;
  //    } else if (latencyMs < pingLimit && pingHigh) {
  //      callObject?.updateParticipant(remoteParticipant.session_id, {
  //        setVideo: true,
  //      });
  //      datadogRum.addAction("High Ping - Camera Action", {
  //        msg: "Camera auto started",
  //        isVideo: true,
  //      });
  //      pingHigh = false;
  //    }
  //  }, [latencyMs]);

  const renderCamIcon = useMemo(() => {
    const localStorageValue = JSON.parse(localStorage.getItem("isCameraOn"));
    if (localStorageValue !== isCameraOn) {
      setIsCameraOn(localStorageValue);
    }

    const shouldRenderCamera = isCameraOn || camOn;

    return (
      <span
        onClick={handleCamera}
        role="presentation"
        className={`cam-icon ${shouldRenderCamera ? "" : "off"}`}
      >
        {shouldRenderCamera ? <CameraIcon /> : <CamOffNewIcon />}
      </span>
    );
  }, [isCameraOn, camOn, latencyMs, pingLimit, handleCamera]);

  const renderCameraOptions = () =>
    cameraOptions?.map((camera) => (
      //  <div className="drop-item" key={camera.label}>
      //    <div className="invite-user-action">
      //      <Form.Check
      //        className="add-check-box"
      //        type="radio"
      //        id={camera.label}
      //        name="camera-action"
      //        label={camera.label}
      //        value={camera.value}
      //        checked={
      //          selectedCamera === camera.value
      //          || camera.value === userCamera?.value
      //        }
      //        onChange={() => handleCameraChange(camera)}
      //      />
      //    </div>
      //  </div>
      <div
        className={`option ${
          selectedCamera === camera.value || camera.value === userCamera?.value
            ? "active"
            : ""
        }`}
        onClick={() => handleCameraChange(camera)}
        // onKeyDown={(e) =>
        //  e.key === "Enter" && handleOptionSelect(option.value)}
        role="presentation"
        key={camera?.label}
      >
        {(selectedCamera === camera.value
          || camera.value === userCamera?.value) && (
          <span className="active-tick mic">
            <ActiveTickIcon />
          </span>
        )}
        {camera.label}
        {camera?.value === "default" && (
          <span className="sublabel">System default</span>
        )}
      </div>
    ));
  const handleDropDownClick = (name) => {
    if (name === showCamDrop) {
      handleSetting("");
      setShowCamDrop("");
      setShowDrop("");
      return;
    }
    handleSetting(name);
    setShowCamDrop(name);
  };
  return (
    <div className="setting-btn-group camera-btn-wrapper" ref={camDropRef}>
      <span
        className={`setting-btn ${showCamDrop === "camera" ? "active" : ""}`}
      >
        <span
          className={`btn-down-icon ${
            showCamDrop === "camera" ? "rotate" : ""
          }`}
          onClick={() => handleDropDownClick("camera")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
        {/* <span onClick={handleCamera} role="presentation" className="cam-icon"> */}
        {renderCamIcon}
        {/* </span> */}
      </span>
      <div
        className={`drop-wrapper ${
          showCamDrop === "camera" ? "show" : "hide"
        } action-dropper`}
      >
        {renderCameraOptions()}
      </div>
    </div>
  );
}

CamSetting.propTypes = {
  handleSetting: PropsTypes.func.isRequired,
};

export default CamSetting;
