/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useRef, useState } from "react";
import DailyIframe from "@daily-co/daily-js";
import { useParams } from "react-router-dom";
import { DailyProvider } from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import { datadogRum } from "@datadog/browser-rum";
import { Helmet } from "react-helmet";
import PlayerGameView from ".";
import GameBanner from "./gameBanner";
import GameSidebar from "./gameSidebar";
import UserContext from "../../../context/UserContext";
import Rail from "../../rail/rail";
import socket from "../../../config/socket";
import intSignConvert from "../../../utils/intSignConvert";
import crossIcon from "../../../assets/images/toast/cross.svg";
import EndCallIcon from "../../../assets/images/toast/close.svg";
import HandInfo from "./handInfo";
import WifiPingIcon from "../../../IconComponents/wifiPingIcon";
import FinalWinnerView from "../../winner";
import winnerConfettiAnimation from "../../../assets/images/playerview/lottie/confetti2.json";
import "../../rail/style.css";
import { dataDogEnv } from "../../../config/keys";
import OneToOneCall from "./oneToOneCall";
import ToastInvite from "../../toast/invite";
import ToastSuccess from "../../toast/success";

let newCallObject;
let endOneToOneCall;
let interval;
let start;
let debounceTimeout;
let inGamePlayer;
let pingNotification = false;
let latency = 0;
let currDate = new Date();

function NewGameView() {
  const {
    user,
    setCallObject,
    callObject,
    gameEvent,
    setGameData,
    allGamesData,
    gameData,
    oneToOneDuration,
    setOneToOneDuration,
    isPlayerLoading,
    // setReactions,
    responsiveScale,
    latencyMs,
    setLatencyMs,
    setMessages,
    pingLimit,
    messages,
  } = useContext(UserContext);
  const { gameId } = useParams();
  const handInfoBox = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(
    allGamesData[gameId]?.viewers?.length ? "rail" : "multi-table"
  );
  const [railFlag, setRailFlag] = useState(false);
  const [sidebarExpand, setSidebarExpand] = useState(true);
  const [latencyColor, setLatencyColor] = useState("");

  const [activeCallNotification, setActiveCallNotification] = useState(true);
  const [showWaitModal, setShowWaitModal] = useState(true);
  const [communityCameraChange] = useState(
    JSON.parse(localStorage.getItem("communityCamera"))?.value
      || allGamesData[gameId]?.communityCamera?.value
      || ""
  );
  const [showHandInfo, setShowHandInfo] = useState(false);
  const [showFinalWinner, setShowFinalWinner] = useState(false);

  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  const FinalWinnerAnimation = {
    loop: false,
    autoplay: true,
    animationData: winnerConfettiAnimation,
  };
  const getJoin = async () => {
    if (user?.role === "dealer" || user?.role === "admin") {
      const dealerToken = allGamesData[gameId]?.dealer?.meetingToken;
      if (!newCallObject) {
        newCallObject = DailyIframe.createCallObject({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          dailyConfig: {
            useDevicePreferenceCookies: true,
          },
        });
        setCallObject(newCallObject);
      }
      newCallObject
        .join({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: dealerToken,
          userName: "dealer",
          startVideoOff:
            JSON.parse(localStorage.getItem("isCameraOn")) !== null
              ? !JSON.parse(localStorage.getItem("isCameraOn"))
              : false,
          startAudioOff:
            JSON.parse(localStorage.getItem("isMicOn")) !== null
              ? !JSON.parse(localStorage.getItem("isMicOn"))
              : false,
          audioSource: JSON.parse(localStorage.getItem("mic"))?.value,
          videoSource: JSON.parse(localStorage.getItem("camera"))?.value,
        })
        .then(async (d) => {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: communityCameraChange,
            },
          });
          newCallObject.startCustomTrack({
            track: stream.getVideoTracks()[0],
            trackName: "community-card",
          });
        })
        .then((data) => {
          console.log("initial community connect ==>", data);
        })
        .catch((error) => {
          console.log("error =>", error);
        });
      const allPlayers = [
        ...allGamesData[gameId].players,
        ...allGamesData[gameId].viewers,
      ];
      if (allPlayers.length > 0) {
        const player = allPlayers[0];
        socket.emit("playerMicChange", {
          gameId,
          userId: player?.id,
          isAudio: player?.isAudio,
        });
      }
    } else {
      const meetToken = allGamesData[gameId]?.players?.find((el) => el.id === user?.id)
        ?.meetingToken
        || allGamesData[gameId]?.viewers?.find((el) => el.id === user?.id)
          ?.meetingToken;

      const spectatorMeetingToken = allGamesData[gameId]?.spectators?.find(
        (el) => el.id === user?.id
      )?.meetingToken;

      const userName = user?.id;
      if ((meetToken || spectatorMeetingToken) && !newCallObject) {
        newCallObject = DailyIframe.createCallObject({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: meetToken || spectatorMeetingToken,
          userName,
          dailyConfig: {
            useDevicePreferenceCookies: true,
          },
        });
        setCallObject(newCallObject);
        newCallObject.join({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: meetToken || spectatorMeetingToken,
          userName,
          startVideoOff: spectatorMeetingToken
            ? true
            : JSON.parse(localStorage.getItem("isCameraOn")) !== null
              ? !JSON.parse(localStorage.getItem("isCameraOn"))
              : false,
          startAudioOff: spectatorMeetingToken
            ? false
            : JSON.parse(localStorage.getItem("isMicOn")) !== null
              ? !JSON.parse(localStorage.getItem("isMicOn"))
              : false,
          audioSource: JSON.parse(localStorage.getItem("mic"))?.value,
          videoSource: JSON.parse(localStorage.getItem("camera"))?.value,
        });
        const allPlayers = [
          ...allGamesData[gameId].players,
          ...allGamesData[gameId].viewers,
        ];
        if (allPlayers?.some((pl) => pl?.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio:
              JSON.parse(localStorage.getItem("isMicOn")) !== null
                ? JSON.parse(localStorage.getItem("isMicOn"))
                : true,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (
      allGamesData[gameId]?.meetingToken
      && allGamesData[gameId]?._id === gameId
    ) {
      getJoin();
    }
    return () => {
      newCallObject?.leave();
      newCallObject?.destroy();
      setCallObject();
    };
  }, [
    allGamesData[gameId]?.meetingToken,
    allGamesData[gameId]?._id === gameId,
  ]);
  useEffect(() => {
    console.log("Called 2");
    // getJoin();
  }, [allGamesData[gameId]?.players]);

  useEffect(() => {
    socket.on("communityCamera", async (data) => {
      if (
        data.gameId === gameId
        && (user.role === "dealer" || user.role === "admin")
      ) {
        callObject.stopCustomTrack("community-card");
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: data?.camera?.value,
          },
        });
        callObject
          .startCustomTrack({
            track: stream.getVideoTracks()[0],
            trackName: "community-card",
          })
          .then((ee) => {
            console.log("cam reUpdated", ee);
          })
          .catch((er) => {
            console.log("err in reUpdate", er);
          });
      }
    });
    return () => {
      socket.off("communityCamera");
    };
  }, [callObject]);

  inGamePlayer = allGamesData[gameId]?.players?.some(
    (pl) => pl?.id === user?.id
  );

  useEffect(() => {
    const handleMouseEvent = (event) => {
      if (!inGamePlayer) {
        return;
      }
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        socket.emit("setMouseActivity", {
          gameId,
          userId: user.id,
          eventType: event.type,
        });
        debounceTimeout = null;
      }, 500);
    };
    let lastSaveTime = 0;
    const handlePing = (ms) => {
      const previousDate = currDate;
      currDate = new Date();
      //  latency = (currDate.getTime() - previousDate.getTime()) / 1000;
      latency = (currDate.getTime() - previousDate.getTime() - 1000) / 10;
      setLatencyMs(latency);
      if (dataDogEnv === "prod") {
        const difference = (currDate.getTime() - lastSaveTime) / 1000;
        if (difference > 180) {
          datadogRum.addAction("User Network Ping", {
            msg: "User Network Ping",
            isAudio: `PING - ${latency} ms`,
          });
          lastSaveTime = currDate.getTime();
        }
      }

      const greenThreshold = 500;
      const orangeThreshold = 2000;

      // Set latency color based on the calculated latency
      if (latency <= greenThreshold) {
        setLatencyColor("green");
        pingNotification = false;
      } else if (latency <= orangeThreshold) {
        setLatencyColor("yellow");
        pingNotification = false;
      } else {
        setLatencyColor("red");
      }

      if (latency > pingLimit && !pingNotification) {
        // setMessages((old) => [
        //  ...old,
        //  {
        //    msg: "Due to high ping Video is auto closed",
        //    id: `highPing-${user?.id}`,
        //    type: "error",
        //  },
        // ]);
        pingNotification = true;
      }
    };

    socket.io.on("ping", handlePing);

    socket.on("forCall", (data) => {
      if (!newCallObject) {
        setGameData(data.game);
      }
    });

    document.addEventListener("mousemove", handleMouseEvent);
    document.addEventListener("mousedown", handleMouseEvent);
    document.addEventListener("mouseup", handleMouseEvent);

    return () => {
      document.removeEventListener("mousedown", handleMouseEvent);
      document.removeEventListener("mousemove", handleMouseEvent);
      document.removeEventListener("mouseup", handleMouseEvent);

      socket.off("forCall");
    };
  }, []);

  const handleCallTimer = (callStartTime) => {
    if (interval) {
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
    }

    interval = setInterval(() => {
      const date1 = new Date().getTime();
      const date2 = new Date(callStartTime);
      const diffTime = (date1 - date2) / 1000;

      if (endOneToOneCall) {
        clearInterval(interval);
        setOneToOneDuration("00:00");
      } else {
        const m = Math.floor(diffTime / 60);
        const s = Math.floor(diffTime % 60);

        const formattedMinutes = m.toString().padStart(2, "0");
        const formattedSeconds = s.toString().padStart(2, "0");

        setOneToOneDuration(`${formattedMinutes}:${formattedSeconds}`);
      }
    }, 1000);
  };

  useEffect(() => {
    const oneToOneRoomData = allGamesData[gameId]?.oneToOneRoom;

    const activeCallExists = oneToOneRoomData?.some(
      (el) => el.requestedBy === user.id || el.requestedTo === user.id
    );

    if (activeCallExists) {
      const activeCallTimestamp = oneToOneRoomData.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.timestamp;

      handleCallTimer(activeCallTimestamp);
      setActiveCallNotification(true);
    } else if (interval) {
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
      setOneToOneDuration("00:00");
    }
  }, [allGamesData[gameId]?.oneToOneRoom?.length]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
    if (activeTab === "rail") {
      setRailFlag(true);
    }
  };

  const handleEndCall = () => {
    endOneToOneCall = true;
    socket.emit("oneToOneLeaveRequest", {
      gameId: allGamesData[gameId].id,
      roomId: allGamesData[gameId]?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
    setOneToOneDuration("00:00");
  };

  const handleHandInfo = () => {
    setShowHandInfo(!showHandInfo);
  };

  useEffect(() => {
    if (railFlag) {
      if (
        !allGamesData[gameId]?.viewers?.length
        && activeTab === "rail"
        && isExpanded
      ) {
        setActiveTab("multi-table");
        setIsExpanded(false);
      } else if (
        !allGamesData[gameId]?.viewers?.length
        && activeTab === "rail"
      ) {
        setActiveTab("multi-table");
      }
      return;
    }
    if (!railFlag) {
      if (!allGamesData[gameId]?.viewers?.length && activeTab === "rail") {
        setActiveTab("multi-table");
      }
    }
    if (isExpanded && allGamesData[gameId]?.viewers?.length > 0) {
      return;
    }
    if (activeTab === "multi-table" && isExpanded) {
      return;
    }
    if (
      allGamesData[gameId]?.viewers?.length > 0
      || (activeTab === "multi-table" && allGamesData[gameId]?.viewers?.length > 0)
    ) {
      setActiveTab("rail");
      setIsExpanded(true);
    } else {
      setActiveTab("multi-table");
      setIsExpanded(false);
    }
  }, [allGamesData[gameId]?.viewers?.length]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowHandInfo(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(handInfoBox);

  useEffect(() => {
    if (allGamesData[gameId]?.gameFinish) {
      setShowFinalWinner(true);
      setTimeout(() => {
        setShowFinalWinner(false);
      }, 5000);
    }
  }, [allGamesData[gameId]?.gameFinish]);

  return (
    <>
      <div
        className={`player-view-main ${
          activeTab === "rail" ? "rail-player-main" : ""
        }`}
      >
        <div
          className="hand-info-view"
          style={{
            transform: `translate(${-topValue}%, ${intSignConvert(
              topValue
            )}%) scale(${scaleValue / 100})`,
          }}
          ref={handInfoBox}
        >
          {!isPlayerLoading && (
            <HandInfo
              handleHandInfo={handleHandInfo}
              showHandInfo={showHandInfo}
            />
          )}
        </div>
        {latencyMs > 0 && dataDogEnv !== "prod" ? (
          <div
            className="ping-wrapper"
            style={{
              transform: `translate(${-topValue}%, ${intSignConvert(
                topValue
              )}%) scale(${scaleValue / 100})`,
            }}
          >
            <div className={`ping-color ${latencyColor}`}>
              <WifiPingIcon />
            </div>
            <span title="Server Ping">
              {latencyMs}
              {" "}
              ms
            </span>
          </div>
        ) : null}
        <div className="container">
          <DailyProvider callObject={callObject}>
            <div className="main-player-game-wrapper">
              <div
                className="player-view-main-wrapper"
                style={{
                  transform: `translate(-50%, ${intSignConvert(
                    topValue
                  )}%) scale(${scaleValue / 100})`,
                }}
              >
                {localStorage.getItem("newPlayerId") === user?.id
                && user?.role === "user"
                && !gameData?.viewers?.some((pl) => pl.id === user?.id)
                && !allGamesData[gameId]?.spectators?.some(
                  (pl) => pl.id === user?.id
                )
                && showWaitModal ? (
                  <div className="add-player-notification">
                    <p>You will be seated as soon as the current hand ends</p>
                    <span
                      onClick={() => setShowWaitModal(false)}
                      role="presentation"
                    >
                      <img src={crossIcon} alt="cross icon" />
                    </span>
                  </div>
                  ) : (
                    ""
                  )}
                {allGamesData[gameId]?.oneToOneRoom?.length
                && activeCallNotification ? (
                  <>
                    <Helmet>
                      <html className="one-to-one-view" lang="en" />
                    </Helmet>
                    {/* <div
                      className="one-to-one-call-notification-wrapper"
                      style={{
                        transform: `translateY(${
                          activeTab === "multi-table" && isExpanded
                            ? "-321px"
                            : activeTab === "rail" && isExpanded
                              ? "-161px"
                              : gameEvent?.games?.length > 1
                            || allGamesData[gameId]?.viewers?.length > 0
                                ? "0px"
                                : "40px"
                        })`,
                      }}
                    > */}
                    <div>
                      {allGamesData[gameId]?.oneToOneRoom.map((el) =>
                        (el?.requestedBy === user?.id
                        || el?.requestedTo === user?.id ? (
                          <>
                            <OneToOneCall />
                            {/* <div
                            className="one-to-one-call-notification"
                            key={el?.roomId}
                          >
                            <div className="one-to-one-notification-wrapper">
                              <div className="one-to-one-player-name">
                                <p>
                                  {el?.requestedBy === user?.id
                                    ? `${el?.requestedToName}`
                                    : `${el?.requestedByName}`}
                                </p>
                                <span>Active 1-1 call</span>
                              </div>
                              <p>{oneToOneDuration}</p>
                            </div>
                            <Button
                              className="rail-end-call"
                              onClick={handleEndCall}
                              title="Disconnect 1-1 Call"
                            >
                              <img src={EndCallIcon} alt="close icon" />
                            </Button>
                          </div> */}
                          </>
                          ) : (
                            ""
                          ))
                      )}
                    </div>
                  </>
                  ) : (
                    ""
                  )}
                <div className="player-game-view-wrapper-top">
                  <div
                    className={`player-game-view-top-left ${
                      sidebarExpand ? "show" : "hide"
                    }`}
                  >
                    <GameBanner
                      gameData={gameEvent}
                      isExpanded={isExpanded}
                      applyCustomHeight={
                        allGamesData[gameId]?.viewers?.length > 0
                      }
                    />

                    <div className="player-game-view-container">
                      <div
                        className={`game-viewport ${
                          allGamesData[gameId]?.gameFinish
                            ? "hide-player-view"
                            : ""
                        }`}
                      >
                        <PlayerGameView />
                      </div>
                      <div
                        className={`game-viewport ${
                          allGamesData[gameId]?.gameFinish
                            ? ""
                            : "hide-winner-view"
                        }`}
                      >
                        <FinalWinnerView gameId={gameId} />
                      </div>

                      {allGamesData[gameId]?.gameFinish
                        && messages.length > 0
                        && messages?.map((message) => {
                          if (message.type === "success") {
                            setTimeout(() => {
                              setMessages((old) =>
                                old.filter((o) => o.id !== message.id)
                              );
                            }, 4000);
                            return (
                              <ToastSuccess
                                key={message.id}
                                message={message.msg}
                                closeAction={() =>
                                  setMessages((old) =>
                                    old.filter((o) => o.id !== message.id)
                                  )}
                              />
                            );
                          }
                          if (message.type === "invite") {
                            return (
                              <ToastInvite
                                key={Math.round()}
                                message={message.msg}
                                closeAction={() => {
                                  if (message.closeAction) message.closeAction();
                                  setMessages((old) =>
                                    old.filter((o) => o.id !== message.id)
                                  );
                                }}
                                successAction={() => {
                                  message.successAction();
                                  setMessages((old) =>
                                    old.filter((o) => o.id !== message.id)
                                  );
                                }}
                              />
                            );
                          }
                          return "";
                        })}
                    </div>
                    {/* For ticket sync */}
                    {/* {(gameEvent?.games?.length > 1
                      || allGamesData[gameId]?.viewers?.length > 0) && (
                      <div className="player-game-view-wrapper-bottom">
                        <Rail
                          isExpanded={isExpanded}
                          handleExpanded={handleExpanded}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    )} */}
                    {allGamesData[gameId]?.viewers?.length > 0 && (
                      <div
                        className={
                          isExpanded
                          && allGamesData[gameId]?.viewers?.filter(
                            (el) => el?.isFinalWinnerInRail
                          )?.length > 7
                            ? "player-game-view-wrapper-bottom rail-open-view"
                            : "player-game-view-wrapper-bottom"
                        }
                      >
                        <Rail
                          isExpanded={isExpanded}
                          handleExpanded={handleExpanded}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`player-game-view-top-right ${
                      sidebarExpand ? "show" : "hide"
                    }`}
                  >
                    <GameSidebar
                      activeTab={activeTab}
                      isExpanded={isExpanded}
                      sidebarExpand={sidebarExpand}
                      setSidebarExpand={setSidebarExpand}
                      normalAction="normalAction"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DailyProvider>
        </div>
      </div>
      {showFinalWinner ? (
        <div className="final-winner-confetti-wrapper">
          <Lottie options={FinalWinnerAnimation} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NewGameView;
