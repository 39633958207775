/* eslint-disable no-param-reassign */
import {
  useAudioTrack,
  useDaily,
  useDevices,
  useVideoTrack,
} from "@daily-co/daily-react";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef } from "react";
import UserContext from "../../../context/UserContext";

function PlayerVideoView({ playerData, loadVideo }) {
//  console.log("playerData", playerData);
  const { user, mirrorVideo } = useContext(UserContext);

  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();

  const isMirrorUser = user?.id === playerData?.id && mirrorVideo;
  const { hasCamError } = devices || {};

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === playerData.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  useEffect(() => {
    if (videoTrack.persistentTrack && videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (audioTrack.persistentTrack && audioRef?.current) {
      //  const isParticipantInCall = oneToOneRoom.find((room) =>
      //    room.roomId.includes(player.id)
      //  );
      //  const isUserInCall = oneToOneRoom.some((room) =>
      //    room.roomId.includes(user.id)
      //  );
      //  audioRef.current.volume =
      //    isParticipantInCall && isParticipantInCall.roomId?.includes(user.id)
      //      ? 1.0
      //      : isParticipantInCall || isUserInCall
      //      ? 0.0
      //      : pokerTableVolume;
      //  audioRef.current.srcObject = new MediaStream([
      //    audioTrack.persistentTrack,
      //  ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    audioTrack.subscribed,
  ]);
  return loadVideo ? (
    remoteParticipant
    && remoteParticipant?.video
    && (!videoTrack?.isOff || !hasCamError)
    && videoTrack?.state === "playable" ? (
      <video
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: isMirrorUser ? "scale(-1, 1)" : "",
          filter: isMirrorUser ? "FlipH" : "",
        }}
        ref={videoRef}
        playsInline
        controls={false}
        autoPlay
        muted
      />
      ) : (
        <div className="main-player-camoff">
          <img
            src={playerData?.avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
        </div>
      )
  ) : (
    <img
      src={playerData?.avatar}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
      }}
      alt=""
    />
  );
}

PlayerVideoView.defaultProps = {
  playerData: {},
  loadVideo: false,
};

PlayerVideoView.propTypes = {
  playerData: PropTypes.object,
  loadVideo: PropTypes.bool,
};

export default PlayerVideoView;
