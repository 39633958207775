/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import dateArrow from "../../../assets/images/game/date-arrow.svg";
import UserContext from "../../../context/UserContext";
import ToastSuccess from "../../toast/success";
import ToastError from "../../toast/erros";
import {
  addGamePlayerSingleLink,
  getAllDealer,
  getGamePlayerSingleLink,
} from "../../../utils/Api";
import {
  csvFileToArray,
  findDuplicateEmails,
  findHostEmails,
  getAllPlayersManage,
  groupEmailsByTable,
  removeNullUndefinedPlayers,
  updatePlayerByTable,
} from "../../../utils";
import { client } from "../../../config/keys";

const customStyles = {
  option: (provided) => ({
    ...provided,
    background: "#454851",
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#4C505E",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: "#454851",
    padding: "0px",
  }),
  control: () => ({
    background: "#454851",
    border: "1px solid #A0AABB",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  }),
};

const optionsInit = [{ value: "", label: "Select Dealer" }];
const optionsHostInit = [{ value: "", label: "Select Host" }];

function ManagePlayers({ gameEvent, closeModal }) {
  const { user, messages, setMessages } = useContext(UserContext);
  const initialData = {
    tables: gameEvent?.games?.map((table) => ({
      id: table?.id,
      host: "",
      players: Array.from({ length: 8 }, () => ({
        gameID: table?.id,
        playerEmail: "",
        playerRole: "user",
      })),
    })),
  };
  const [gamePayload, setGamePayload] = useState(initialData);
  const [options, setOptions] = useState(optionsInit);
  const [optionsHost, setOptionsHost] = useState(optionsHostInit);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [duplicateData, setDuplicateData] = useState([]);

  const handleUploadCsv = async (event) => {
    setIsLoadingCsv(true);
    const selectedFile = event.target.files[0];
    try {
      if (selectedFile) {
        setGamePayload(initialData);
        const text = await selectedFile.text();
        const data = csvFileToArray(text);
        const tableData = groupEmailsByTable(data, gameEvent?.games);
        if (tableData?.tables.length > 0) {
          setGamePayload(tableData);
          setMessages((oldMessages) => [
            ...oldMessages,
            { msg: "CSV is uploaded", id: "CSVUpdated", type: "success" },
          ]);
        } else {
          setMessages((oldMessages) => [
            ...oldMessages,
            { msg: "Failed to upload CSV", id: "CSVUpdateFail", type: "error" },
          ]);
        }
      }
    } catch (error) {
      console.error("Error in upload CSV", error);
      setMessages((oldMessages) => [
        ...oldMessages,
        { msg: "Failed to upload CSV", id: "CSVUpdateFail", type: "error" },
      ]);
    } finally {
      setIsLoadingCsv(false);
    }
  };

  const setHost = async (tableData) => {
    const playersList = getAllPlayersManage({ tables: tableData });
    const hostData = playersList.filter((item) => item.playerRole === "user");
    const hostOptions = [];
    hostData.map(async (hostItem) => {
      hostOptions.push({
        value: hostItem?.playerEmail,
        label: hostItem?.playerEmail,
      });
    });
    setOptionsHost(hostOptions);
  };

  const handleEmailChange = (email, gameId, role, tableIndex, playerIndex) => {
    const updatedTables = [...gamePayload.tables];
    const updatedPlayers = [...updatedTables[tableIndex].players];
    updatedPlayers[playerIndex] = {
      gameID: gameId,
      playerEmail: email ?? "",
      playerRole: role ?? "user",
    };
    updatedTables[tableIndex] = {
      ...updatedTables[tableIndex],
      players: updatedPlayers,
    };
    const data = removeNullUndefinedPlayers(updatedTables);
    setGamePayload({ ...gamePayload, tables: data });
    setHost(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const players = getAllPlayersManage(gamePayload);
    const duplicatePlayersData = findDuplicateEmails(players);
    const hostDetails = players.filter(
      (p) => p.playerEmail === gamePayload?.tables[0]?.host
    );
    const hostObj = {
      gameID: hostDetails.length > 0 ? hostDetails[0].gameID : "",
      playerEmail: gamePayload?.tables[0]?.host,
      playerRole: "host",
    };
    players.push(hostObj);
    const isValidDublicate = gamePayload?.tables[0]?.host !== ""
      ? findHostEmails(players, gamePayload?.tables[0]?.host)
      : [];
    let finalDublicateData = [];
    if (duplicatePlayersData.length > 0 || isValidDublicate.length > 0) {
      finalDublicateData = [...duplicatePlayersData, ...isValidDublicate];
    }
    setDuplicateData(finalDublicateData);

    const payload = {
      gameEventID: gameEvent?.id,
      createdById: user?.id,
      players,
    };

    try {
      if (finalDublicateData.length === 0) {
        await addGamePlayerSingleLink(payload);
        setMessages((oldMessages) => [
          ...oldMessages,
          {
            msg: "Form data is updated",
            id: "updateFormData",
            type: "success",
          },
        ]);
      } else {
        setMessages((oldMessages) => [
          ...oldMessages,
          {
            msg: "Form has duplicate data",
            id: "duplicateFormData",
            type: "error",
          },
        ]);
      }
    } catch (error) {
      console.error("Error in player data submit", error);
      setMessages((oldMessages) => [
        ...oldMessages,
        { msg: "Error in form data", id: "errorInFormData", type: "error" },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const onHostChange = (email) => {
    const updatedTables = [...gamePayload.tables];
    updatedTables.forEach((tableRow, index) => {
      const rowData = { ...tableRow };
      const updatedPlayers = [...updatedTables[index].players];

      rowData.host = email;
      updatedTables[index] = {
        ...rowData,
        players: updatedPlayers,
      };
    });
    const data = removeNullUndefinedPlayers(updatedTables);
    setGamePayload({ ...gamePayload, tables: data });
  };
  useEffect(() => {
    const eventId = gameEvent?.id;
    const getEventPlayers = async () => {
      const res = await getGamePlayerSingleLink(eventId);
      const tableData = updatePlayerByTable(res?.gamePlayers, gamePayload);
      if (tableData.length > 0) {
        setGamePayload({ ...gamePayload, tables: tableData });
        setHost(tableData);
      }
    };
    const getAllDealerHandler = async () => {
      const res = await getAllDealer();
      console.log("res", res);
      const dealerOptions = [];
      res?.results?.map(async (data) => {
        dealerOptions.push({
          value: data?.email,
          label: `${data?.firstName} ${data?.lastName}`,
        });
      });
      setOptions(dealerOptions);
    };

    if (eventId) {
      getEventPlayers();
    }
    getAllDealerHandler();
  }, []);

  const isFieldEmpty = gamePayload.tables?.every((element) =>
    element?.players?.every((ee) => ee.playerEmail === "")
  );

  return (
    <div className="game-details manage-players-wrapper">
      <Form id="managePlayersForm" onSubmit={handleSubmit}>
        <div className="game-details-head">
          <span
            className="intro-back-arrow"
            onClick={closeModal}
            role="presentation"
          >
            <img src={arrow} alt="" />
          </span>
          <h2>Manage Players</h2>
          <div className="details-action-btn manage-player-btns-wrapper">
            <a
              href={`${client}sampleManagePlayer.csv`}
              target="_blank"
              rel="noopener noreferrer"
              className="no-btn"
            >
              Download Sample
            </a>
            <Button
              className="upload-csv-btn outline-btn"
              htmlFor="uploadCsv"
              disabled={isLoadingCsv}
            >
              Upload CSV
              <input
                type="file"
                accept=".csv"
                id="uploadCsv"
                onChange={handleUploadCsv}
              />
            </Button>
          </div>
        </div>
        <div className="manage-players-grid">
          {gameEvent?.games?.map((game, tableIndex) => (
            <div className="manage-players-table-wise-content" key={game?.id}>
              <h4>{`Table #${game?.tableNumber}`}</h4>
              <Form.Group className="form-group">
                <div className="player-select">
                  <Select
                    classNamePrefix="select"
                    styles={customStyles}
                    className={
                      duplicateData.includes(
                        gamePayload?.tables[tableIndex]?.players[0]?.playerEmail
                      )
                        ? "duplicate-field"
                        : ""
                    }
                    placeholder="Select Dealer"
                    options={options}
                    value={options?.find(
                      (e) =>
                        e.value
                        === gamePayload?.tables[tableIndex]?.players[0]?.playerEmail
                    )}
                    onChange={(event) =>
                      handleEmailChange(
                        event.value,
                        game?.id,
                        "dealer",
                        tableIndex,
                        0
                      )}
                  />
                  <div className="game-time-zone">
                    <img src={dateArrow} alt="date arrow" />
                  </div>
                </div>
              </Form.Group>
              <div className="manage-players-table-wise-wrapper generate-form">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((e) => (
                  <Form.Group className="form-group" key={e}>
                    <Form.Control
                      className={
                        duplicateData.includes(
                          gamePayload?.tables[tableIndex]?.players[e]
                            ?.playerEmail
                        )
                          ? "duplicate-field"
                          : ""
                      }
                      type="text"
                      placeholder={`Player ${e}`}
                      name={`Player${e}`}
                      value={
                        gamePayload?.tables[tableIndex]?.players[e]?.playerEmail
                      }
                      onChange={(event) =>
                        handleEmailChange(
                          event.target.value,
                          game?.id,
                          "user",
                          tableIndex,
                          e
                        )}
                    />
                  </Form.Group>
                ))}
              </div>
            </div>
          ))}

          <div className="manage-players-table-wise-content">
            <h4>HOST</h4>
            <Form.Group className="form-group">
              <div className="player-select">
                <Select
                  classNamePrefix="select"
                  styles={customStyles}
                  className={
                    duplicateData.includes(gamePayload?.tables[0]?.host)
                      ? "duplicate-field"
                      : ""
                  }
                  placeholder="Select Host"
                  options={optionsHost}
                  value={optionsHost?.find(
                    (e) => e.value === gamePayload?.tables[0]?.host
                  )}
                  onChange={(event) => onHostChange(event.value)}
                />
                <div className="game-time-zone">
                  <img src={dateArrow} alt="date arrow" />
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="details-action-btn">
          <Button
            className="save-btn"
            type="submit"
            disabled={isLoading || isFieldEmpty}
          >
            {isLoading ? <Spinner animation="border" /> : "Submit"}
          </Button>
        </div>
      </Form>
      {messages?.map((message1) => {
        if (message1.type === "error") {
          setTimeout(() => {
            setMessages((old) => old.filter((o) => o.id !== message1.id));
          }, 4000);
          return (
            <ToastError
              key={message1.id}
              message={message1.msg}
              closeAction={() =>
                setMessages((old) => old.filter((o) => o.id !== message1.id))}
            />
          );
        }
        if (message1.type === "success") {
          setTimeout(() => {
            setMessages((old) => old.filter((o) => o.id !== message1.id));
          }, 4000);
          return (
            <ToastSuccess
              key={message1.id}
              message={message1.msg}
              closeAction={() =>
                setMessages((old) => old.filter((o) => o.id !== message1.id))}
            />
          );
        }
        return "";
      })}
    </div>
  );
}

ManagePlayers.propTypes = {
  gameEvent: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ManagePlayers;
