/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import PropsTypes from "prop-types";
import UserContext from "../../../context/UserContext";
import PlayersView from "./playerView";
import socket from "../../../config/socket";
import GameCreation from "../../gameCreation/gamecreation";
import { getAllGamePlayers } from "../../../utils/Api";
import Layout from "../../layout/layout";
import loadericon from "../../../assets/images/dealer/loader.json";
import ToastSuccess from "../../toast/success";
import ToastError from "../../toast/erros";
import ToastInvite from "../../toast/invite";
import timerOutSound from "../../../assets/sounds/timer_running_out.mp3";
import InvitePopUp from "../../toast/InvitePopUp";
import "./style.css";
import getRandomColor from "../../../utils/generateRandomColor";

let tRound = null;
let tPlayer = null;
// let players = [];
let game;
let profile;
let gameWinners = [];
let d = 0;

let staticPlayersLeft = [];
let staticPlayersRight = [];

const LoaderAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadericon,
};

let allPlayers;

function PlayerGameView({ isExpanded }) {
  const RoundName = {
    0: "players",
    1: "preflopround",
    2: "flopround",
    3: "turnround",
    4: "riverround",
    5: "showdown",
  };
  const {
    user,
    setGamePlayers,
    setOneToOneRoom,
    setShowProfileModal,
    messages,
    setMessages,
    setGameEvent,
    allGamesData,
    setAllGamesData,
    gameEvent,
    isPlayerLoading,
    setIsPlayerLoading,
    setValues,
    setAllGamePlayers,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const { gameId } = useParams();
  const [timer, setTimer] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState();
  const [remainingTime, setRemainingTime] = useState(0);
  const [recentPlayer, setRecentPlayer] = useState();
  const [showGameCreation, setShowGameCreation] = useState(false);
  const [playersLeft, setPlayerLeft] = useState([]);
  const [playersRight, setPlayersRight] = useState([]);
  const [winners, setWinners] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [action, setAction] = useState({});
  const [gamePaused, setGamePaused] = useState("");
  const gameData = allGamesData[gameId];
  const [bet, setBet] = useState(false);
  const [raise, setRaise] = useState(false);
  const [isInOneToOneRoom, setIsInOneToOneRoom] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [oneToOneParticipants] = useState({});
  const [openAction, setOpenAction] = useState({
    bet: false,
    call: false,
    raise: false,
    check: false,
    allin: false,
    fold: false,
  });
  const [timerType, setTimerType] = useState("timer");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  profile = queryParams.get("modal");
  const handleAcceptOneToOneRequest = (data) => {
    socket.emit("oneToOneRequestAccept", data);
  };

  const handleRejectOneToOneRequest = (data) => {
    socket.emit("oneToOneRequestReject", data);
  };

  const handleSound = (value, play = true) => {
    const audioElement = document.getElementById(value);
    const playAudio = play && !isPlaying;

    setIsPlaying(playAudio);

    if (audioElement) {
      audioElement.muted = !playAudio;
      if (playAudio) {
        audioElement.play();
      } else {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    }
  };

  const handlePlaySound = (value) => handleSound(value, true);

  const handleStopSound = (value) => handleSound(value, false);

  allPlayers = gameData?.players?.concat(gameData?.viewers);
  const getGamePlayerAll = async () => {
    let allGamePlayers = [];
    gameEvent.games.map(async (gameTable) => {
      const gameWiseData = gameTable;
      const allData = gameWiseData?.players
        ?.concat(gameWiseData?.viewers)
        .concat(gameWiseData?.spectators);
      allGamePlayers = allGamePlayers.concat(allData);
    });
    const userIDs = (allGamePlayers || []).map((el) => el.id);
    const users = userIDs.length ? await getAllGamePlayers(userIDs) : [];
    if (users.length) {
      setAllGamePlayers(users);
    }
  };
  const getAllPlayers = useCallback(async () => {
    const userIDs = (allPlayers || []).map((el) => el.id);
    const users = userIDs.length ? await getAllGamePlayers(userIDs) : [];
    if (users.length) {
      setGamePlayers(users);
    }
    getGamePlayerAll();
  }, [allPlayers, getAllGamePlayers]);

  useEffect(() => {
    let newCallObject;
    const handleSubmit = async () => {
      getAllPlayers();
    };

    if (gameData?.players?.length || gameData?.viewers?.length) {
      handleSubmit();
    }

    return () => newCallObject?.leave();
  }, [gameData?.players?.length, gameData?.viewers?.length]);

  //  useEffect(() => {
  //    if (gameData?.id === gameId) {
  //      if (!gameData?.videoTrack) {
  //        return;
  //      }
  //      if (
  //        localStorage.getItem("videoKey") === gameId
  //        || gameData?.viewers?.find((el) => el.id === user?.id)
  //      ) {
  //        setShowIntro(false);
  //      } else {
  //        // localStorage.setItem("videoKey", gameId);
  //        setShowIntro(true);
  //      }
  //    }
  //  }, [gameData?.videoTrack]);

  useEffect(() => {
    if (user) {
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameData: !!(gameData && Object.keys(gameData)?.length),
        userId: user?.id,
      });
    }
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            tPlayer = null;
            tRound = null;
            socket.emit("checkTable", {
              gameId,
              gameData: !!(gameData && Object.keys(gameData)?.length),
              userId: user?.id,
            });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    socket.on("connect", () => {
      tPlayer = null;
      tRound = null;
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  // previous code
  //  const updatePlayers = (pls) => {
  //    const pl = [...pls];
  //    players = pl.filter((p) => !game.leavereq.includes(p.id));
  //    const pRight = pl.slice(0, Math.ceil(pl.length / 2));
  //    const pleft = pl.slice(Math.ceil(pl.length / 2)).reverse();
  //    setPlayerLeft(pleft);
  //    setPlayersRight(pRight);
  //    if (staticPlayersLeft.length !== pleft.length) {
  //      staticPlayersLeft = pleft;
  //      //  console.log("static player assign", staticPlayersLeft);
  //    }
  //    if (staticPlayersRight.length !== pRight.length) {
  //      staticPlayersRight = pRight;
  //      //  console.log("static player right assing", staticPlayersRight);
  //    }
  //  };

  const updatePlayers = (pls) => {
    if (pls?.length === 0 || !pls) {
      return;
    }
    const pl = [...pls];
    let pRight = [];
    let pleft = [];
    const leftLimit = Math.floor(pl.length / 2);
    const rightLimit = pl.length - leftLimit;

    const currPlayer = pl?.findIndex((el) => el.id === user?.id);
    if (currPlayer >= 0) {
      let count = 0;
      let startIndex = currPlayer - (rightLimit - 1);
      if (startIndex < 0) startIndex += pl.length;

      while (count < pl?.length) {
        count += 1;
        if (pRight?.length === rightLimit) {
          pleft = [pl[startIndex], ...pleft];
        } else if (pRight?.length < rightLimit) {
          pRight = [...pRight, pl[startIndex]];
        }
        if (pleft === leftLimit && pRight === rightLimit) break;
        startIndex = (startIndex + 1) % pl.length;
      }
    } else {
      pRight = pl?.slice(0, Math.ceil(pl.length / 2));
      pleft = pl?.slice(Math.ceil(pl.length / 2))?.reverse();
    }

    setPlayerLeft(pleft);
    setPlayersRight(pRight);

    if (staticPlayersLeft?.length !== pleft?.length) {
      staticPlayersLeft = pleft;
    }
    if (staticPlayersRight?.length !== pRight?.length) {
      staticPlayersRight = pRight;
    }
  };

  const handleTentativeAction = (player) => {
    let event;
    const { tentativeAction } = player;
    if (player?.tentativeAction?.includes(" ")) {
      const [event1] = tentativeAction.split(" ");
      event = event1;
    } else {
      event = tentativeAction;
    }

    switch (event) {
      case "check":
        socket.emit("docheck", { gameId, userId: player?.id });
        break;
      case "fold":
        socket.emit("dofold", { gameId, userId: player?.id });
        break;
      case "check/fold":
        if (game.lastAction === "check" || game.raiseAmount === player?.pot) {
          socket.emit("docheck", { gameId, userId: player?.id });
        } else {
          socket.emit("dofold", { gameId, userId: player?.id });
        }
        break;
      case "call":
        if (game?.lastAction === "check" || game?.raiseAmount === player?.pot) {
          socket.emit("docheck", { gameId, userId: player?.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player?.id,
            amount: game?.raiseAmount,
          });
        }
        break;
      case "callAny":
        if (game?.lastAction === "check" || game?.raiseAmount === player?.pot) {
          socket.emit("docheck", { gameId, userId: player?.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player?.id,
            amount: game?.raiseAmount,
          });
        }
        break;
      case "allin":
        if (game?.lastAction === "check" || game?.raiseAmount === player?.pot) {
          socket.emit("docheck", { gameId, userId: player?.id });
        } else {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        }
        break;
      default:
        return "";
    }
  };

  const handleActionButton = (currentPl) => {
    setBet(false);
    setRaise(false);
    const currentAction = { ...openAction };
    const { pot, wallet } = currentPl || {};
    const { raiseAmount, lastAction, runninground: round } = game || {};
    currentAction.fold = true;
    if (round === 1) {
      if (raiseAmount === pot) {
        // check true
        currentAction.check = true;
        currentAction.call = false;
      } else if (wallet + pot > raiseAmount) {
        // call true
        currentAction.call = true;
        currentAction.bet = false;
        currentAction.check = false;
      }
      if (wallet + pot > raiseAmount) {
        // range true
        currentAction.raise = true;
        currentAction.bet = false;
      } else if (wallet + pot <= raiseAmount) {
        // allin true
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }
    }

    if (round >= 2) {
      if (lastAction === "check" || raiseAmount === pot) {
        currentAction.check = true;
      }

      if (lastAction === "check") {
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
        } else {
          currentAction.call = false;
          currentAction.bet = true;
          currentAction.raise = false;
        }
      } else {
        if (wallet + pot > raiseAmount) {
          currentAction.call = true;
          currentAction.bet = false;
          currentAction.check = false;
        }
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
        }
        if (raiseAmount < wallet + pot) {
          currentAction.allin = false;
          currentAction.bet = false;
          currentAction.raise = true;
        }
      }
      if (wallet + pot <= raiseAmount) {
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }

      if (lastAction !== "check" && pot !== raiseAmount) {
        currentAction.check = false;
      }
    }
    setOpenAction(() => currentAction);
  };

  useEffect(() => {
    socket.on("connect", () => {
      // eslint-disable-next-line no-console
      console.log("connected");
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameEventId: gameEvent?.games?.find(
          (eachGameId) => eachGameId === gameId
        ),
        gameData: !!(gameData && Object.keys(gameData)?.length),
        userId: user?.id,
      });
    });

    socket.on("updateGame", (data) => {
      let roomData;
      if (data?.game?.id === gameId) {
        if (
          game?.pot === 0
          && game?.sidePots?.length === 0
          && game?.runninground === 5
          && game?.winnerPlayer?.length
        ) {
          const p = [];
          game.showdown.forEach((pl) => {
            const newP = data.game.players.find((newPl) => newPl.id === pl.id);
            if (newP) {
              p.push({ ...newP, cards: pl.cards });
            }
          });
          roomData = {
            ...data.game,
            players: p,
            runninground: 5,
            viewers: game?.viewers,
            viewersRank: game?.viewersRank,
          };
          const checkSpecInGame = data.gameEvent?.games?.find((el) =>
            el?.spectators?.find((pl) => pl.id === user?.id)
          );
          if (
            gameId !== checkSpecInGame?.id
            && user?.role !== "dealer"
            && checkSpecInGame
          ) {
            window.location.href = `/user/main-game/${checkSpecInGame?.id}`;
          }
          setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
          if (data.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        } else {
          roomData = data.game;
          setWinners(data?.game?.winnerPlayer);
          gameWinners = data?.game?.winnerPlayer;
          game = data.game;
          setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
          if (data.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        }
        setIsPlayerLoading(false);
        setOneToOneRoom(roomData?.oneToOneRoom);
        setGamePaused("");
        if (game?.pause) {
          setGamePaused("gamePaused");
        } else if (
          game?.runninground === 1
          && game?.preflopround?.find((pl) => pl.cards.length !== 2 && pl.playing)
        ) {
          setGamePaused("usercardScanning");
        } else if (game.runninground === 2 && game.communityCard.length < 3) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 3 && game.communityCard.length < 4) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 4 && game.communityCard.length < 5) {
          setGamePaused("communityCardScanning");
        } else {
          setGamePaused("");
        }
        // find game
        const checkGame = data.gameEvent?.games?.find(
          (el) =>
            el?.players?.find((pl) => pl.id === user?.id)
            || el?.waitingPlayers?.find((pl) => pl.id === user?.id)
            || el?.viewers?.find((pl) => pl.id === user?.id)
        );
        if (gameId !== checkGame?.id && user?.role === "user" && checkGame) {
          //  window.location.href = "/";
          window.location.href = `/user/main-game/${checkGame?.id}`;
        }
        if (roomData?.runninground === 0) {
          updatePlayers(roomData.players);
          localStorage.removeItem("newPlayerId");
          if (!data.gameEvent) return;
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (
            user?.role === "user"
            && !(
              data?.gameEvent?.games?.find(
                (g) =>
                  conditionCheck("players", g)
                  || conditionCheck("viewers", g)
                  || conditionCheck("spectators", g)
                  || conditionCheck("lobbyPlayers", g)
              )
              || conditionCheck("players")
              || conditionCheck("viewers")
            )
          ) {
            localStorage.removeItem("videoKey");
            return navigate("/");
          }
        } else if (roomData.runninground === 1) {
          setWinners([]);
          gameWinners = [];
          updatePlayers(roomData.preflopround);
          if (
            !roomData?.preflopround?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
            && !roomData?.viewers?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
          ) {
            localStorage.setItem("newPlayerId", user?.id);
          }
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (user?.role === "user" && !conditionCheck("players")) {
            if (!conditionCheck("spectators") && !conditionCheck("viewers")) {
              localStorage.removeItem("videoKey");
              return navigate("/");
            }
          }
        } else if (roomData.runninground === 2) {
          updatePlayers(roomData.flopround);
          if (
            !roomData?.flopround?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
            && !roomData?.viewers?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
          ) {
            localStorage.setItem("newPlayerId", user?.id);
          }
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (user?.role === "user" && !conditionCheck("players")) {
            if (!conditionCheck("spectators") && !conditionCheck("viewers")) {
              localStorage.removeItem("videoKey");
              return navigate("/");
            }
          }
        } else if (roomData.runninground === 3) {
          updatePlayers(roomData.turnround);
          if (
            !roomData?.turnround?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
            && !roomData?.viewers?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
          ) {
            localStorage.setItem("newPlayerId", user?.id);
          }
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (user?.role === "user" && !conditionCheck("players")) {
            if (!conditionCheck("spectators") && !conditionCheck("viewers")) {
              localStorage.removeItem("videoKey");
              return navigate("/");
            }
          }
        } else if (roomData.runninground === 4) {
          updatePlayers(roomData.riverround);
          if (
            !roomData?.riverround?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
            && !roomData?.viewers?.find(
              (el) => el.id.toString() === user?.id.toString()
            )
          ) {
            localStorage.setItem("newPlayerId", user?.id);
          }
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (user?.role === "user" && !conditionCheck("players")) {
            if (!conditionCheck("spectators") && !conditionCheck("viewers")) {
              localStorage.removeItem("videoKey");
              return navigate("/");
            }
          }
        } else if (roomData.runninground === 5) {
          updatePlayers(roomData.showdown);
          localStorage.removeItem("newPlayerId");
          const conditionCheck = (field, obj = roomData) =>
            obj[field]?.find(
              (el) => el?.id?.toString() === user?.id?.toString()
            );
          if (user?.role === "user" && !conditionCheck("players")) {
            if (!conditionCheck("spectators") && !conditionCheck("viewers")) {
              localStorage.removeItem("videoKey");
              return navigate("/");
            }
          }
        }
        if (data?.profileUpdate) {
          getAllPlayers();
          setShowProfileModal(false);
        }
      }
    });

    socket.on("OnlyOne", () => {
      // TODO show message to wait for other players join
    });

    socket.on("actionError", () => {
      // TODO
    });

    socket.on("actionperformed", (data) => {
      if (data?.gameId === gameId) {
        setRecentPlayer(data);
        if (data.action === "fold") {
          setAction(data);
          setTimeout(() => setAction({}), 3000);
        }
      }
    });

    socket.on("onlyOnePlayingPlayer", (data) => {
      if (data?.game?.id === gameId) {
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (data?.game?.players?.length === 0) {
          setPlayerLeft([]);
          setPlayersRight([]);
          staticPlayersLeft = [];
          staticPlayersRight = [];
        }
      }
    });

    socket.on("playerleft", () => {
      // TODO
    });

    socket.on("tablestopped", () => {
      // TODO
    });

    socket.on("roomFinished", () => {
      // TODO
    });

    socket.on("newhand", (data) => {
      if (data?.game?.id === gameId) {
        game.gamestart = false;
        game.pot = 0;
        game.sidePots = [];
        tPlayer = null;
        tRound = null;
        if (data?.gameEvent) {
          setGameEvent(data.gameEvent);
        }
        const p = [];
        game?.showdown?.forEach((pl) => {
          const newP = data?.game?.players?.find((newPl) => newPl.id === pl.id);
          if (newP) {
            p.push({ ...newP, cards: pl.cards });
          }
        });
        if (data?.game?.players?.length === 1) {
          const f = {
            ...game,
            players: p,
            viewers: data.game.viewers,
            viewersRank: data.game.viewersRank,
            showdown: [],
            runninground: data.game.runninground,
          };
          game = f;
          updatePlayers(p);
          setAllGamesData((prev) => ({
            ...prev,
            [data.game.id]: { ...f },
          }));
          setGameEvent(data.gameEvent);
        }

        if (data?.game?.runninground === 0) {
          localStorage.removeItem("newPlayerId");
        }
      }
    });

    socket.on("scanCardForUser", (data) => {
      if (data.game.id === gameId) {
        game = data.game;
        localStorage.removeItem("showCard");
        updatePlayers(game?.preflopround);
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (data?.gameEvent) {
          setGameEvent(data.gameEvent);
        }
        setCurrentPlayer({});
        setShowCards(false);
        setWinners([]);
        gameWinners = [];
        setGamePaused("usercardScanning");
      }
    });

    // card scans on seach round socket
    socket.on("scanCommunityCard", (data) => {
      if (data.game.id === gameId) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setTimer(game.timer);
        setCurrentPlayer({});
        if (game.runninground === 0) {
          localStorage.removeItem("showCard");
          updatePlayers(game.players);
        } else if (game.runninground === 1) {
          localStorage.removeItem("showCard");
          updatePlayers(game.preflopround);
        } else if (game.runninground === 2) {
          setTimeout(() => {
            updatePlayers(game.flopround);
          }, 1000);
        } else if (game.runninground === 3) {
          localStorage.removeItem("showCard");
          setTimeout(() => {
            updatePlayers(game.turnround);
          }, 1000);
        } else if (game.runninground === 4) {
          setTimeout(() => {
            updatePlayers(game.riverround);
          }, 1000);
        } else if (game.runninground === 5) {
          updatePlayers(game.showdown);
        }
        setGamePaused("communityCardScanning");
      }
    });

    socket.on("winner", async (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        tPlayer = null;
        tRound = null;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (game?.winnerPlayer[0]?.handName) {
          setShowCards(true);
        }
        updatePlayers(game?.showdown);
        setCurrentPlayer({});
        setTimeout(() => {
          setWinners(game?.winnerPlayer);
          gameWinners = game?.winnerPlayer;
        }, 2000);
      }
    });

    socket.on("roomResume", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("");
      }
    });

    socket.on("roomPaused", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("gamePaused");
        localStorage.removeItem("isActed");
      }
    });

    // one-to-one room sockets
    socket.on("oneToOneRequest", (data) => {
      if (data.gameId === gameId) {
        if (user.id === data.requestedTo) {
          setMessages((old) => [
            ...old,
            {
              ...data,
              msg: `${data.requestedByName} invites you to a 1-1 call`,
              id: data.requestedBy + data.requestedTo,
              type: "invite",
              successAction: () => {
                handleAcceptOneToOneRequest(data);
              },
              closeAction: () => {
                handleRejectOneToOneRequest(data);
              },
            },
          ]);
        }
      }
    });

    socket.on("oneToOneRequestRejected", (data) => {
      if (data.gameId === gameId) {
        setMessages((old) =>
          old?.filter((msg) => msg.id !== data.requestedBy + data.requestedTo)
        );
        if (user?.id === data.requestedBy) {
          setMessages((old) => [
            ...old,
            {
              ...data,
              msg: `${data.requestedToName} declined your 1-1 call request`,
              id: data.requestedBy + data.requestedTo,
              type: "error",
            },
          ]);
        }
      }
    });

    socket.on("oneToOneRequestAccepted", (data) => {
      if (data.game.id === gameId) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setMessages((old) =>
          old?.filter(
            (msg) =>
              msg.id !== data.requestedBy + data.requestedTo
              || !(
                msg.id.includes(data.requestedBy)
                || msg.id.includes(data.requestedTo)
              )
          )
        );
        setMessages((old) =>
          old?.filter(
            (msg) =>
              !(
                msg.id.includes(data.requestedBy)
                || msg.id.includes(data.requestedTo)
              )
          )
        );
        if (user.id === data.requestedBy || user.id === data.requestedTo) {
          setIsInOneToOneRoom(!isInOneToOneRoom);
        }
        setOneToOneRoom(game.oneToOneRoom);
        if (user?.id !== data.requestedTo && user?.id !== data.requestedBy) {
          setMessages((old) => [
            ...old,
            {
              msg: `${data.requestedByName} and ${data.requestedToName} Joined a 1-1 call`,
              id: data.requestedBy + data.requestedTo,
              type: "oneToNotification",
            },
          ]);
        }
      }
    });

    socket.on("oneToOneRoomClosed", (data) => {
      if (data.game.id === gameId) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setIsInOneToOneRoom(!isInOneToOneRoom);
        setOneToOneRoom(game.oneToOneRoom);
      }
    });

    socket.on("notInvited", () => {
      window.location.href = `/user/${
        gameData?.viewers?.find((el) => el.id === user.id)
        || gameData?.players?.find((el) => el.id === user.id)
          ? "main-game"
          : "video-setting"
      }/${gameId}`;
    });

    socket.on("notFound", (data) => {
      if (
        data.message === "Game not found. Either game is finished or not exist"
      ) {
        navigate("*");
      }
    });
    socket.on("newViewerJoin", (data) => {
      if (data?.gameId === gameId) {
        if (user?.id !== data.userId) {
          const isExist = messages.some((item) => item.id === data.userId);
          if (!isExist) {
            setMessages((old) => [
              ...old,
              {
                msg: data.msg,
                id: data.userId,
                type: "newViewers",
              },
            ]);
          }
        }
      }
    });

    socket.on("chatSpamNotification", (data) => {
      if (data?.gameId === gameId) {
        if (user?.id === data.userId) {
          setMessages((old) => [
            ...old,
            {
              msg: data.msg,
              id: `${getRandomColor()}-${Date.now()}-${data.userId}`,
              type: "spamNotification",
            },
          ]);
        }
      }
    });

    return () => {
      socket.off("connect");
      socket.off("updateGame");
      socket.off("OnlyOne");
      socket.off("actionError");
      socket.off("actionperformed");
      socket.off("onlyOnePlayingPlayer");
      socket.off("playerleft");
      socket.off("tablestopped");
      socket.off("roomFinished");
      socket.off("newhand");
      socket.off("scanCardForUser");
      socket.off("scanCommunityCard");
      socket.off("winner");
      socket.off("roomResume");
      socket.off("roomPaused");
      socket.off("oneToOneRequest");
      socket.off("oneToOneRequestRejected");
      socket.off("oneToOneRequestAccepted");
      socket.off("oneToOneRoomClosed");
      socket.off("notInvited");
      socket.off("notFound");
      socket.off("newViewerJoin");
      socket.off("chatSpamNotification");
    };
  }, []);

  useEffect(() => {
    socket.on("timer", (data) => {
      if (data?.gameId?.toString() === gameId?.toString()) {
        setRemainingTime(data.playerchance);
        if (data.timerType === "timebank") {
          setTimerType("timebank");
          setTimer(data.maxtimer);
        }
        if (data?.playerchance === 5) {
          handlePlaySound("timer-out");
        }
        d += 1;
        game.timer = data.maxtimer;
        if (
          (tPlayer !== data.id || tRound !== data.runninground)
          && data.id === user.id
        ) {
          setValues(0);
        }
        if (tPlayer !== data.id || tRound !== data.runninground || d === 5) {
          d = 0;
          if (timer === 0) {
            setTimer(game?.timer);
          }

          const activePlayer = game[RoundName[data.runninground]]?.find(
            (ele) => ele.id === data.id
          );
          if (activePlayer?.id === user?.id) {
            if (profile) {
              setMessages((old) => [
                ...old,
                {
                  ...data,
                  msg: `${user?.name} It's Your Turn`,
                  id: user?.id,
                  type: "playerTurn",
                },
              ]);
            }
          }
          if (activePlayer?.tentativeAction && activePlayer?.id === user?.id) {
            if (
              activePlayer?.tentativeAction === "check"
              && game?.lastAction !== "Allin"
            ) {
              if (
                game?.lastAction === "check"
                || game?.raiseAmount === activePlayer?.pot
              ) {
                handleTentativeAction(activePlayer);
                setCurrentPlayer(activePlayer);
              } else {
                setTimerType("timer");
                setCurrentPlayer(activePlayer);
                handleActionButton(activePlayer);
              }
            } else {
              handleTentativeAction(activePlayer);
              setCurrentPlayer(activePlayer);
            }
          } else {
            setTimerType("timer");
            setCurrentPlayer(activePlayer);
            handleActionButton(activePlayer);
          }
          if (data.playerchance === 5 && activePlayer?.id === user.id) {
            handlePlaySound("timer-out");
          }

          setRecentPlayer((old) => old);
        }
        tPlayer = data.id;
        tRound = data.runninground;
      }
    });
    socket.on("profileUpdated", () => {
      getGamePlayerAll();
    });
    return () => {
      socket.off("timer");
      socket.off("profileUpdated");
    };
  }, [gameId]);

  const handleOneToOneRequest = (requestedTo, name) => {
    socket.emit("oneToOneRequest", {
      requestedBy: user.id,
      requestedTo,
      requestedToName: name,
      requestedByName: user.name,
      gameId,
    });
    setMessages((old) => [
      ...old,
      {
        msg: "Request has been send",
        id: user.id + requestedTo,
        type: "success",
      },
    ]);
  };

  useEffect(() => {
    if (gameData) {
      if (gameData?.runninground === 1) {
        setValues((old) => {
          if (old !== 0 && old !== gameData.raiseAmount * 2) {
            return old;
          }
          return gameData.raiseAmount * 2;
        });
      } else {
        setValues((old) => {
          if (old !== 0 && old !== gameData.raiseAmount) {
            return old;
          }
          return gameData.raiseAmount;
        });
      }
    }
  }, [gameData?.runninground, currentPlayer]);

  useEffect(() => {
    const noPlayersExist = gameData?.players?.length === 0;
    const userIsSpectator = gameData?.spectators?.some(
      (sp) => sp?.id === user?.id
    );

    if (noPlayersExist && userIsSpectator) {
      setPlayerLeft([]);
      setPlayersRight([]);
      staticPlayersLeft = [];
      staticPlayersRight = [];
    }
  }, [gameData?.players?.length, gameData?.spectators?.length, user?.id]);

  return (
    <Layout>
      <>
        <Helmet>
          <body className="game-view" />
        </Helmet>
        {isPlayerLoading ? (
          <div className="loader">
            <Lottie options={LoaderAnimation} />
          </div>
        ) : (
          <div className="player-game-view tournament-game-view">
            <div className="container">
              <div className="player-game-view-wrapper">
                <PlayersView
                  playersLeft={playersLeft}
                  playersRight={playersRight}
                  staticPlayersLeft={staticPlayersLeft}
                  staticPlayersRight={staticPlayersRight}
                  action={action}
                  currentPlayer={currentPlayer}
                  timer={timer}
                  remainingTime={remainingTime}
                  showCards={showCards}
                  recentPlayer={recentPlayer}
                  winners={winners}
                  handleOneToOneRequest={handleOneToOneRequest}
                  oneToOneParticipants={oneToOneParticipants}
                  openAction={openAction}
                  setOpenAction={setOpenAction}
                  bet={bet}
                  setBet={setBet}
                  raise={raise}
                  setRaise={setRaise}
                  setTimer={setTimer}
                  gamePaused={gamePaused}
                  gameData={allGamesData[gameId]}
                  handleStopSound={handleStopSound}
                  timerType={timerType}
                  multi={isExpanded}
                  gameWinners={gameWinners}
                />
              </div>
            </div>
            <Modal
              show={showGameCreation}
              onHide={() => setShowGameCreation(false)}
              className="game-creation-modal"
              centered
            >
              <Modal.Body>
                <GameCreation handleClose={() => setShowGameCreation(false)} />
              </Modal.Body>
            </Modal>
          </div>
        )}

        {messages.length > 0
          && messages?.map((message) => {
            if (message.type === "success") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 4000);
              return (
                <ToastSuccess
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            if (message.type === "error") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 4000);
              return (
                <ToastError
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            if (message.type === "invite") {
              return (
                <ToastInvite
                  key={message.id}
                  message={message.msg}
                  closeAction={() => {
                    if (message.closeAction) message.closeAction();
                    setMessages((old) =>
                      old.filter((o) => o.id !== message.id)
                    );
                  }}
                  successAction={() => {
                    message.successAction();
                    setMessages((old) =>
                      old.filter((o) => o.id !== message.id)
                    );
                  }}
                />
              );
            }
            if (message.type === "playerTurn") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 5000);
              return (
                <ToastError
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            if (message.type === "timebank") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 4000);
              return (
                <ToastSuccess
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            if (message.type === "newViewers") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 8000);
              return (
                <ToastSuccess
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            if (message.type === "oneToNotification") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 8000);
              return (
                <InvitePopUp
                  key={message.id}
                  message={message.msg}
                  closeAction={() => {
                    if (message.closeAction) message.closeAction();
                    setMessages((old) =>
                      old.filter((o) => o.id !== message.id)
                    );
                  }}
                />
              );
            }
            if (message.type === "spamNotification") {
              setTimeout(() => {
                setMessages((old) => old?.filter((o) => o.id !== message.id));
              }, 4000);
              return (
                <ToastError
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) =>
                      old?.filter((o) => o.id !== message.id)
                    )}
                />
              );
            }
            return "";
          })}
        {/* audios */}
        {user?.id === currentPlayer?.id && (
          <audio
            src={timerOutSound}
            id="timer-out"
            muted={user?.id !== currentPlayer?.id}
            preload="auto"
            controls={false}
            style={{ display: "none" }}
          >
            <track kind="captions" srcLang="en" label="English" />
          </audio>
        )}
      </>
    </Layout>
  );
}

PlayerGameView.defaultProps = {
  isExpanded: false,
};

PlayerGameView.propTypes = {
  isExpanded: PropsTypes.bool,
};
export default PlayerGameView;
