/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useDaily } from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import JoinErrorModal from "../waitingRoomPopup/joinErrorModal";
import WaitingRoomParticipant from "./WaitngRoomParticipant";
import CPNFullLogo from "../../../IconComponents/CPNFullLogo";
// import CrossIcon from "../../../IconComponents/crossIcon";
import CounDown from "../../../assets/video/countDown.mp4";
import TableView from "./TableView";

// import ProfileForm from "../../spectators/profileForm";
import socket from "../../../config/socket";
import LeaveRoomModal from "../waitingRoomPopup/leaveRoomModal";
import UserContext from "../../../context/UserContext";
import Chat from "../../chat/chat";

// import Chat1 from "./Chat";

function WaitingRoomCamera({
  waitingPlayers,
  isHost,
  isBroadcasting,
  isHostData,
  hostUserData,
  setLeaveRoom,
  leaveRoom,
  sidebarExpand,
  chats
}) {
  const navigation = useNavigate();
  const { gameId } = useParams();
  const callObject = useDaily();
  const {
    user,
    // responsiveScale,
    gameEvent,
    showCountDown,
    setShowCountDown,
    setShowBroadcastControl,
    showBroadcastControl,
    setShowIntroVideo
  } = useContext(UserContext);
  const [errorPopup, setErrorPopup] = useState(false);
  //  const [leaveRoom, setLeaveRoom] = useState(false);
  const IsHostOnSameTable = gameId === hostUserData.gameID;
  //  Screen Scaling Function Start
  //  const { scaleValue } = responsiveScale;
  //
  const toggleLeavePopup = () => {
    setLeaveRoom(!leaveRoom);
  };

  const handleLeaveTable = async () => {
    socket.emit("leaveWatingRoom", { gameId, userId: user.id });
    await callObject.leave();
    navigation("/");
  };

  useEffect(() => {
    if (waitingPlayers.length > 8) {
      setErrorPopup((old) => !old);
    }
  }, [waitingPlayers.length]);

  const memoizedParticipants = useMemo(
    () =>
      [...Array(8)].map((_, index) => (
        <WaitingRoomParticipant
          user={waitingPlayers.length > index ? waitingPlayers[index] : null}
          position={index}
          key={`item-${index + 1}`}
          isHost={
            waitingPlayers.length > index
              ? isHost?.toLowerCase()
                === waitingPlayers[index].email?.toLowerCase()
              : false
          }
          isBroadcasting={isBroadcasting}
          isSameTable={
            showBroadcastControl && isBroadcasting === false
              ? true
              : gameId === hostUserData?.gameID
          }
        />
      )),
    [waitingPlayers, isBroadcasting, showBroadcastControl]
  );
  const onRedirect = () => {
    const page = localStorage.getItem("page");
    if (page === "waiting") {
      setShowIntroVideo(true)
    //  navigation(`/user/intro-room/${gameId}`, {
    //    state: { from: "waitingroom" },
    //  });
    } else {
      window.location.href = `/user/main-game/${gameId}`;
    }
    setShowCountDown(false);
  };
  const handleUpdateBroadcast = (status) => {
    if (isHost?.toLowerCase() === user.email.toLowerCase() && !isBroadcasting) {
      setShowBroadcastControl(status);
    }
  };

  //  const [chats, setChats] = useState([]);
  //  const [actualAllChats, setActualAllChats] = useState([]);

  //  const chatByTableId = (allChats, tableId) => {
  //    if (!tableId) return allChats;
  //    if (allChats?.length > 0) {
  //      return allChats?.filter((el) => el.gameId === tableId);
  //    }
  //  };
  useEffect(() => {
    if (gameEvent?.id) {
      socket.emit("getChats", { gameEventId: gameEvent.id });
    }
  }, [gameEvent?.id]);
  //  useEffect(() => {
  //    socket.on("newMessage", (data) => {
  //      //  setActualAllChats(data.chats);
  //      const tableViseChats = chatByTableId(data.chats, "");
  //      setChats(tableViseChats);
  //      //  setChatCount(data?.chats?.length);
  //    });

  //    return () => {
  //      socket.off("newMessage");
  //    };
  //  }, [gameEvent]);
  return (
    <div className="wr-camera-content">
      <Helmet>
        <body className="waiting-page" />
      </Helmet>
      <div
        className="wr-camera-grid"
        style={{ paddingTop: sidebarExpand ? "120px" : "24px" }}
      >
        <div className="wr-camera-gridbox">
          {memoizedParticipants}
          {isHostData
          && isBroadcasting
          && hostUserData
          && !IsHostOnSameTable ? (
            <WaitingRoomParticipant
              user={hostUserData}
              position={8}
              key="item-8"
              isHost
              isBroadcasting={isBroadcasting}
              sessionID={isHostData}
            />
            ) : !showCountDown ? (
              <div className="waiting-center-box content-9">
                <CPNFullLogo />
                <p>Your game will begin shortly...</p>
              </div>
            ) : (
              <video
                src={CounDown}
                id="spectator-video"
                preload="auto"
                playsInline
                muted
                autoPlay
                onEnded={onRedirect}
              />
            )}
        </div>
        {!sidebarExpand && (
          <div className="viewer-host-wrapper">
            {isHost.toLowerCase() === user.email.toLowerCase() && (
              <Button
                className="bg-btn spectator-save-btn"
                onClick={() => handleUpdateBroadcast(true)}
              >
                Host Broadcast Controls
              </Button>
            )}
            {/* <ProfileForm
            showLeave
            scaleValue={scaleValue}
            toggleLeavePopup={toggleLeavePopup}
          /> */}
            {/* <Button className="delete-btn leave-waiting-btn" onClick={toggleLeavePopup}>
              Leave
              <CrossIcon />
            </Button> */}
            <TableView gameEvent={gameEvent} />
            {/* <Chat1 chats={chats} /> */}
            <Chat chats={chats} tableData="All Tables" isExpanded />
          </div>
        )}
      </div>
      <JoinErrorModal
        joinError={errorPopup}
        handleJoinError={handleLeaveTable}
      />
      <LeaveRoomModal
        visible={leaveRoom}
        toggleLeavePopup={toggleLeavePopup}
        handleLeaveTable={handleLeaveTable}
        title="Are you sure want to leave?"
        subtitle="All your data was saved you can came back play again whenever you want"
      />
    </div>
  );
}

WaitingRoomCamera.defaultProps = {
  waitingPlayers: [],
  isHost: "",
  isBroadcasting: false,
  isHostData: null,
  hostUserData: {},
};

WaitingRoomCamera.propTypes = {
  waitingPlayers: PropTypes.array,
  isHost: PropTypes.string,
  isBroadcasting: PropTypes.bool,
  leaveRoom: PropTypes.bool.isRequired,
  isHostData: PropTypes.string,
  hostUserData: PropTypes.object,
  setLeaveRoom: PropTypes.func.isRequired,
  sidebarExpand: PropTypes.bool.isRequired,
  chats: PropTypes.array.isRequired,
};

export default WaitingRoomCamera;
